export const introduce = {
  title: {
    KR: "회사소개",
    EN: "Company Introduction",
  },
  info: {
    title: {
      KR: "루미르 주식회사  <span>Lumir Inc.</span>",
      EN: "Lumir Inc.",
    },
    content: {
      KR: "지구관측 인공위성 전문기업",
      EN: "Earth Observation Satellite Specialist",
    },
    KR: [
      {
        title: "주소",
        content:
          "경기도 용인시 수지구 신수로 767,<br/> 분당수지 U-TOWER A동 1103호",
      },
      { title: "기업형태", content: "상장예비심사 승인 (2024. 7. 11)" },
      {
        title: "대표이사",
        content: "남명용",
      },
      { title: "설립일", content: "2009년 11월 2일" },
      { title: "직원수", content: "70명 +" },
      { title: "업종", content: "항공기용 부품 제조업" },
      {
        title: "주요사업",
        content:
          "인공위성 시스템<br/>인공위성 탑재장치<br/>위성기반 정보 서비스",
      },
      { title: "정부 R&D 과제 수주 금액", content: "249억 +" },
      { title: "대한민국 인공위성 개발 수주", content: "28억 +" },
    ],
    EN: [
      {
        title: "Address",
        content:
          "1103, U-TOWER A, 767, Sinsu-ro, Suji-gu, Yongin-si, Gyeonggi-do",
      },
      { title: "Corporate Form", content: "Preliminary Examination Approval" },
      {
        title: "CEO",
        content: "Myungyong Nam",
      },
      { title: "Establishment Date", content: "November 2, 2009" },
      { title: "Number of Employees", content: "70 +" },
      { title: "Industry", content: "Manufacture of Aircraft Parts" },
      {
        title: "Main Business",
        content:
          "Satellite System<br/>Satellite Mounting Device<br/>Satellite-based Information Service",
      },
      {
        title: "Government R&D Project Contract Amount",
        content: "249 billion +",
      },
      {
        title: "Korea Satellite Development Contract",
        content: "28 billion +",
      },
    ],
  },
  philosophy: {
    title: {
      KR: "기업이념  <span>Corporate Philosophy</span>",
      EN: "Lumir's Corporate Philosophy",
    },
    KR: [
      {
        title: "기업미션",
        content:
          "우리는 고객, 주주, 임직원의 신뢰를 통해<br/>지속적으로 성장하여 <span>겨레</span>와 <span>나라</span>에 기여한다.",
      },
      {
        title: "기업비전",
        content:
          "인공위성 시장에서 <span>자체기술</span> 기반으로<br/>영상 제공 및 데이터 서비스를 제공하는 <span>글로벌 기업</span>",
      },
      {
        title: "핵심경영",
        content: [
          "<b>L</b>oyalty - 고객 충성 신뢰 바탕의 경영<br/><b>U</b>plift - 지속적인 기술 서비스 개선을 통한 성공<br/><b>M</b>ission-driven - 미션 중심 경영<br/><b>I</b>nnovation - 끊임없는 혁신을 통한 경쟁력 강화<br/><b>R</b>ule Respect - 합리적 원칙의 설정과 중시",
        ],
      },
      {
        title: "핵심가치",
        content: [
          "<p>Harmonia et Differentia</p>서로 <span>화합</span>하고 <span>조화</span>를 이루는 가운데 다른 생각을 가진<br/>개인의 독창성과 자유를 인정하고 포용하는 열린 기업",
        ],
      },
    ],
    EN: [
      {
        title: "Corporate Mission",
        content:
          "We contribute to the <span>country</span> and <span>nation</span> by continuously growing through the trust of customers, shareholders, and employees.",
      },
      {
        title: "Corporate Vision",
        content:
          "A <span>global company</span> that provides image and data services based on <span>proprietary technology</span> in the satellite market.",
      },
      {
        title: "Core Management",
        content:
          "<b>L</b>oyalty - Management based on customer loyalty and trust<br/><b>U</b>plift - Success through continuous improvement of technical services<br/><b>M</b>ission-driven - Mission-centered management<br/><b>I</b>nnovation - Enhancing competitiveness through constant innovation<br/><b>R</b>ule Respect - Setting and valuing rational principles",
      },
      {
        title: "Core Values",
        content: [
          "<b>Harmonia et Differentia</b>",
          "An open company that recognizes and embraces the originality and freedom of individuals with different thoughts while <span>harmonizing</span> and <span>harmonizing</span> with each other.",
        ],
      },
    ],
  },
  ceo: {
    title: {
      KR: "CEO 인사말  <span>CEO Greetings</span>",
      EN: "CEO Greetings",
    },
  },
  organization: {
    title: {
      KR: "회사조직 <span>Company Organizationt</span>",
      EN: "Company Organization",
    },
  },
  ci: {
    title: {
      KR: "CI <span>Corporate Identity</span>",
      EN: "Corporate Identity",
    },
    contents: {
      KR: {
        symbol: {
          title: "심벌마크",
          subTitle:
            "고구려의 자주성과 독립 정신의 상징 <span>삼족오</span>, 겨레와 나라를 위한 기술을 개발하는 기업 루미르",
          desc: [
            "날개를 활짝 핀 삼족오의 형상은 보다 크고 넓게 성장하는 기업의 비전을 상징합니다.",
            "미래 감성에 맞게 재해석되어 표현된 삼족오의 비주얼은 미래와 우주를 향한 루미르의 도약과 성장을 의미합니다.",
          ],
          img: "/assets/imgs/renewal/introduce/overview/삼족오.png",
        },
        colorSystem: {
          title: "컬러시스템",
          colors: [
            {
              img: "/assets/imgs/renewal/introduce/overview/삼족오_blue.png",
              cmyk: "C100",
              rgb: "G160 / B233",
            },
            {
              img: "/assets/imgs/renewal/introduce/overview/삼족오_purple.png",
              cmyk: "C70 / M100 / K10",
              rgb: "R101 / G18 / B126",
            },
            {
              img: "/assets/imgs/renewal/introduce/overview/삼족오_black.png",
              cmyk: "K100",
              rgb: "RGB0",
            },
          ],
          desc: [
            "오로라 컬러의 태양, 우주, 지구는 신비한 미지의 공간을 개척하는 우주산업의 성장에 일조하는 루미르를 의미합니다.",
          ],
        },
      },
      EN: {
        symbol: {
          title: "Symbol Mark",
          subTitle:
            "The symbol of the independence spirit of Goguryeo, <span>Samjoko</span>, Lumir, a company that develops technology for the country and the nation",
          desc: [
            "The shape of Samjoko, with its wings wide open, symbolizes the vision of a company that grows bigger and wider.",
            "The visual representation of Samjoko, reinterpreted to suit future sensibilities, signifies Lumir's leap and growth towards the future and space.",
          ],
          img: "/assets/imgs/renewal/introduce/overview/삼족오.png",
        },
        colorSystem: {
          title: "Color System",
          colors: [
            {
              img: "/assets/imgs/renewal/introduce/overview/삼족오_blue.png",
              cmyk: "C100",
              rgb: "G160 / B233",
            },
            {
              img: "/assets/imgs/renewal/introduce/overview/삼족오_purple.png",
              cmyk: "C70 / M100 / K10",
              rgb: "R101 / G18 / B126",
            },
            {
              img: "/assets/imgs/renewal/introduce/overview/삼족오_black.png",
              cmyk: "K100",
              rgb: "RGB0",
            },
          ],
          desc: [
            "The aurora colors of the sun, space, and earth represent Lumir, which contributes to the growth of the space industry by exploring the mysterious unknown space.",
          ],
        },
      },
    },
  },
};
