const data = {
  pageSize: "58",
  totalCount: 58,
  totalPage: 1,
  isLast: true,
  isFirst: true,
  items: [
    {
      id: 72,
      createdAt: "2024-09-10T15:05:46.558Z",
      updatedAt: "2024-09-10T15:05:46.558Z",
      title: "루미르, 우주기술 민수산업 확장",
      mediaCompany: "파이낸셜투데이",
      link: "https://www.ftoday.co.kr/news/articleView.html?idxno=328570",
      exposure: true,
      serverFileName: "9d277dd9-5c27-46a7-84e7-6d99837a3857.jpg",
      originFileName: "20240910_renewel.jpg",
      fileUrl:
        "https://lumir-media.s3.amazonaws.com/board/9d277dd9-5c27-46a7-84e7-6d99837a3857.jpg",
      language: "KR",
    },
    {
      id: 71,
      createdAt: "2024-08-29T08:33:56.244Z",
      updatedAt: "2024-08-29T08:33:56.244Z",
      title: "'상장 도전' 루미르, 우주‧항공 IPO 잔혹사 끊을까",
      mediaCompany: "DealSite",
      link: "https://dealsite.co.kr/articles/127393",
      exposure: true,
      serverFileName: "1f7b21f8-7fde-429a-9dfd-ecf50f63a2fd.jpg",
      originFileName: "20240829_renewel.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/1f7b21f8-7fde-429a-9dfd-ecf50f63a2fd.jpg",
      language: "KR",
    },
    {
      id: 70,
      createdAt: "2024-08-16T17:52:51.171Z",
      updatedAt: "2024-08-16T17:52:51.171Z",
      title:
        "[파워인터뷰 화제人] 남명용 루미르 주식회사 대표 “초고해상도, 초소형 위성의 상업화 목표”",
      mediaCompany: "머니투데이",
      link: "https://news.mt.co.kr/mtview.php?no=2024081214357050945",
      exposure: true,
      serverFileName: "4a53c74c-12ae-4262-99ab-332e93ffd261.jpg",
      originFileName: "20240816_renewel.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/4a53c74c-12ae-4262-99ab-332e93ffd261.jpg",
      language: "KR",
    },
    {
      id: 69,
      createdAt: "2024-07-18T15:44:19.102Z",
      updatedAt: "2024-07-22T19:01:42.736Z",
      title: "정부, 우수기업연구소 24곳 선정…병역특례 우대 등 혜택",
      mediaCompany: "파이낸셜뉴스",
      link: "https://www.fnnews.com/news/202407180957422528",
      exposure: true,
      serverFileName: "3e2ab9c1-a01b-4b39-a39b-dab3f0fe1e4a.jpg",
      originFileName: "20240718_renewel.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/3e2ab9c1-a01b-4b39-a39b-dab3f0fe1e4a.jpg",
      language: "KR",
    },
    {
      id: 68,
      createdAt: "2024-07-18T15:43:40.042Z",
      updatedAt: "2024-07-18T15:43:40.042Z",
      title: "'초소형 SAR위성 영상·정보 서비스' 루미르, 코스닥 상장 예심 승인",
      mediaCompany: "프라임경제",
      link: "https://www.newsprime.co.kr/news/article/?no=646258",
      exposure: true,
      serverFileName: "d8d46986-26b1-490d-b619-a3a0aafe9ffa.jpg",
      originFileName: "제목을-입력해주세요_-001 (8).jpg",
      fileUrl:
        "https://lumir-media.s3.amazonaws.com/board/d8d46986-26b1-490d-b619-a3a0aafe9ffa.jpg",
      language: "KR",
    },
    {
      id: 67,
      createdAt: "2024-04-23T09:56:29.719Z",
      updatedAt: "2024-04-23T09:56:29.719Z",
      title: '남명용 대표 “루미르, 국가 공헌하는 우주기업 될것"',
      mediaCompany: "더벨",
      link: "https://www.thebell.co.kr/free/content/ArticleView.asp?key=202404121439172560109620",
      exposure: true,
      serverFileName: "80b152bd-014c-4241-9d09-63ac9f5273b7.jpg",
      originFileName: "20240419.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/80b152bd-014c-4241-9d09-63ac9f5273b7.jpg",
      language: "KR",
    },
    {
      id: 66,
      createdAt: "2024-04-23T09:55:14.353Z",
      updatedAt: "2024-04-23T09:55:14.353Z",
      title:
        "LumirX 항공시험 성능 검증 마쳐…기존 대비 2배 큰 실험실 갖춘 신사옥 건설",
      mediaCompany: "더벨",
      link: "https://www.thebell.co.kr/free/content/ArticleView.asp?key=202404111542425560104615",
      exposure: true,
      serverFileName: "321c4a5a-6ab9-440b-955d-f259c2ddd81e.jpg",
      originFileName: "20240418.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/321c4a5a-6ab9-440b-955d-f259c2ddd81e.jpg",
      language: "KR",
    },
    {
      id: 58,
      createdAt: "2024-04-23T09:53:43.160Z",
      updatedAt: "2024-04-23T09:53:43.160Z",
      title: "'밸류체인 자부심' 루미르, 연내 IPO 드라이브",
      mediaCompany: "더벨",
      link: "https://thebell.co.kr/free/content/ArticleView.asp?key=202404091002404600104061&lcode=00&page=1&svccode=00",
      exposure: true,
      serverFileName: "78151166-7d35-4f2d-98fe-f9fd03a645b2.jpg",
      originFileName: "20240415.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/78151166-7d35-4f2d-98fe-f9fd03a645b2.jpg",
      language: "KR",
    },
    {
      id: 57,
      createdAt: "2024-04-23T09:52:45.821Z",
      updatedAt: "2024-04-23T09:52:45.821Z",
      title: "'K-글로벌 프로젝트' 성적표 보니…일자리·매출·투자 두자릿수↑",
      mediaCompany: "머니투데이",
      link: "https://news.mt.co.kr/mtview.php?no=2024040409285315825",
      exposure: true,
      serverFileName: "e7b66f95-9530-4386-b63d-d8da84b366ff.jpg",
      originFileName: "20240404.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/e7b66f95-9530-4386-b63d-d8da84b366ff.jpg",
      language: "KR",
    },
    {
      id: 56,
      createdAt: "2024-04-23T09:51:40.468Z",
      updatedAt: "2024-04-23T09:51:40.468Z",
      title: "초소형 인공위성 개발 기업 ‘루미르’ 코스닥 상장 도전",
      mediaCompany: "매일경제",
      link: "https://www.mk.co.kr/news/stock/10971753",
      exposure: true,
      serverFileName: "2b465d87-d829-415f-86cb-aff27999bb9c.jpg",
      originFileName: "20240322.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/2b465d87-d829-415f-86cb-aff27999bb9c.jpg",
      language: "KR",
    },
    {
      id: 55,
      createdAt: "2024-04-23T09:50:35.867Z",
      updatedAt: "2024-04-23T09:50:35.867Z",
      title: "‘우주시대 열린다’···IPO 단골손님 예고한 우주항공 스타트업",
      mediaCompany: "시사저널",
      link: "https://www.sisajournal-e.com/news/articleView.html?idxno=401433",
      exposure: true,
      serverFileName: "6d06b596-11d9-4dfd-a58d-c9aef6144644.jpg",
      originFileName: "20240321.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/6d06b596-11d9-4dfd-a58d-c9aef6144644.jpg",
      language: "KR",
    },
    {
      id: 54,
      createdAt: "2024-04-23T09:49:40.066Z",
      updatedAt: "2024-04-23T09:49:40.066Z",
      title:
        "‘2023 대한민국 ICT 대상’ 루미르㈜, 과학기술정보통신부 장관 표창 수상",
      mediaCompany: "이투뉴스",
      link: "https://www.e2news.com/news/articleView.html?idxno=304386",
      exposure: true,
      serverFileName: "cdc4eecd-a9c4-44c4-84a8-d08af804e422.jpg",
      originFileName: "20231226.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/cdc4eecd-a9c4-44c4-84a8-d08af804e422.jpg",
      language: "KR",
    },
    {
      id: 53,
      createdAt: "2024-04-23T09:48:33.444Z",
      updatedAt: "2024-04-23T09:48:33.444Z",
      title:
        "루미르(주), `2023 대한민국 ICT 대상` 과학기술정보통신부 장관 표창 수상",
      mediaCompany: "디지털타임스",
      link: "https://www.dt.co.kr/contents.html?article_no=2023121302109923062003",
      exposure: true,
      serverFileName: "74cb8479-5940-4c0b-a5df-59161a734f20.jpg",
      originFileName: "20231212.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/74cb8479-5940-4c0b-a5df-59161a734f20.jpg",
      language: "KR",
    },
    {
      id: 52,
      createdAt: "2024-04-23T09:46:50.411Z",
      updatedAt: "2024-04-23T09:46:50.411Z",
      title: "K-스타트업 우주시장 개척 꿈…복권 판매금 80억으로 지원사격",
      mediaCompany: "머니투데이",
      link: "https://news.mt.co.kr/mtview.php?no=2023121116480413138",
      exposure: true,
      serverFileName: "668739b9-577c-4abb-839c-997c95aa6f42.jpg",
      originFileName: "20231211.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/668739b9-577c-4abb-839c-997c95aa6f42.jpg",
      language: "KR",
    },
    {
      id: 51,
      createdAt: "2024-04-23T09:44:01.886Z",
      updatedAt: "2024-04-23T09:44:01.886Z",
      title: "400억 투자 받은 'K-인공위성', IPO 본격화",
      mediaCompany: "딜사이트",
      link: "https://dealsite.co.kr/articles/114201",
      exposure: true,
      serverFileName: "488ced0c-f75d-4ac5-b020-1610055b2547.jpg",
      originFileName: "20231207.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/488ced0c-f75d-4ac5-b020-1610055b2547.jpg",
      language: "KR",
    },
    {
      id: 50,
      createdAt: "2024-04-23T09:42:21.354Z",
      updatedAt: "2024-04-23T09:42:21.354Z",
      title: "미래 방위산업 이끌 중소·벤처 17곳 혁신기업 선정",
      mediaCompany: "국방일보",
      link: "https://kookbang.dema.mil.kr/newsWeb/20231106/3/ATCE_CTGR_0010010000/view.do",
      exposure: true,
      serverFileName: "c6cd2af1-f5be-4a52-a2aa-7a94ef61da88.jpg",
      originFileName: "20231105.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/c6cd2af1-f5be-4a52-a2aa-7a94ef61da88.jpg",
      language: "KR",
    },
    {
      id: 49,
      createdAt: "2024-04-23T09:41:26.007Z",
      updatedAt: "2024-04-23T09:41:26.007Z",
      title: "누리호보다 성능 3배 높은 ‘차세대 발사체’ 개발 본격화한다",
      mediaCompany: "동아일보",
      link: "https://www.donga.com/news/It/article/all/20230611/119715870/1",
      exposure: true,
      serverFileName: "75fb6d5e-a497-43ae-a279-05ff5aece2e0.jpg",
      originFileName: "20230612.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/75fb6d5e-a497-43ae-a279-05ff5aece2e0.jpg",
      language: "KR",
    },
    {
      id: 48,
      createdAt: "2024-04-23T09:40:13.815Z",
      updatedAt: "2024-04-23T09:40:13.815Z",
      title: "누리호가 쏘아올린 위성들…“6기 정상 작동 중”",
      mediaCompany: "KBS뉴스",
      link: "https://news.kbs.co.kr/news/pc/view/view.do?ncd=7694027&ref=A",
      exposure: true,
      serverFileName: "e8af6102-70cf-4f13-9fad-c8ebcb0c2f85.jpg",
      originFileName: "20230607.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/e8af6102-70cf-4f13-9fad-c8ebcb0c2f85.jpg",
      language: "KR",
    },
    {
      id: 47,
      createdAt: "2024-04-23T09:38:49.140Z",
      updatedAt: "2024-04-23T09:38:49.140Z",
      title: "차세대 2호 이어 도요샛 1호·루미르·카이로 위성 신호 확인",
      mediaCompany: "연합뉴스",
      link: "https://www.yna.co.kr/view/AKR20230525183651017?input=1195m",
      exposure: true,
      serverFileName: "2017d773-63a7-4fa7-acb2-3695fcdb21f6.jpg",
      originFileName: "20230525.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/2017d773-63a7-4fa7-acb2-3695fcdb21f6.jpg",
      language: "KR",
    },
    {
      id: 46,
      createdAt: "2024-04-23T09:36:37.097Z",
      updatedAt: "2024-04-23T09:36:37.097Z",
      title: "누리호 발사 D-9, 민간 위성들의 우주 임무는?",
      mediaCompany: "KBS뉴스",
      link: "https://news.kbs.co.kr/news/pc/view/view.do?ncd=7675227&ref=A",
      exposure: true,
      serverFileName: "d335eff1-0664-4c87-b064-ee08589f103a.jpg",
      originFileName: "20230515.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/d335eff1-0664-4c87-b064-ee08589f103a.jpg",
      language: "KR",
    },
    {
      id: 45,
      createdAt: "2024-04-23T09:31:37.770Z",
      updatedAt: "2024-04-23T09:31:37.770Z",
      title: "中企 기술력, 정부 주도 실용위성 사업도 통했다",
      mediaCompany: "딜사이트",
      link: "https://dealsite.co.kr/articles/103390",
      exposure: true,
      serverFileName: "d3ad8ab8-266f-4ef4-aa55-fd4e0b12746c.jpg",
      originFileName: "20230510.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/d3ad8ab8-266f-4ef4-aa55-fd4e0b12746c.jpg",
      language: "KR",
    },
    {
      id: 44,
      createdAt: "2024-04-23T09:30:11.506Z",
      updatedAt: "2024-04-23T09:30:11.506Z",
      title: "3차 발사 앞둔 누리호… 큐브위성 루미르T1 점검",
      mediaCompany: "서울신문",
      link: "https://www.seoul.co.kr/news/society/science-news/2023/05/08/20230508008006",
      exposure: true,
      serverFileName: "b2a0c746-c68f-4efd-8d2e-164f2b272d69.jpg",
      originFileName: "20230508.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/b2a0c746-c68f-4efd-8d2e-164f2b272d69.jpg",
      language: "KR",
    },
    {
      id: 43,
      createdAt: "2024-04-23T09:29:23.146Z",
      updatedAt: "2024-04-23T09:29:23.146Z",
      title:
        "민간기업 개발 ‘큐브 위성’도 함께 실려… 기상현상 관측-방사능 분포 탐지 역할",
      mediaCompany: "동아일보",
      link: "https://www.donga.com/news/It/article/all/20230425/118988181/1",
      exposure: true,
      serverFileName: "3652a6e8-5da1-4b2f-996c-d3ebf723f2f6.jpg",
      originFileName: "20230425.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/3652a6e8-5da1-4b2f-996c-d3ebf723f2f6.jpg",
      language: "KR",
    },
    {
      id: 42,
      createdAt: "2024-04-23T09:26:36.787Z",
      updatedAt: "2024-04-23T09:26:36.787Z",
      title: "항공우주연구원 “실용위성 쏘는 누리호 3차 발사는 진정한 검증대”",
      mediaCompany: "동아일보",
      link: "https://www.donga.com/news/It/article/all/20230402/118645914/1",
      exposure: true,
      serverFileName: "1d215fec-ad8a-4b59-9090-cdfff6e4a276.jpg",
      originFileName: "20230403.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/1d215fec-ad8a-4b59-9090-cdfff6e4a276.jpg",
      language: "KR",
    },
    {
      id: 41,
      createdAt: "2024-04-23T09:10:53.336Z",
      updatedAt: "2024-04-23T09:10:53.336Z",
      title: "누리호, 5월 10일 3차 발사…차세대 소형위성 싣고 간다",
      mediaCompany: "한국경제",
      link: "https://www.hankyung.com/it/article/2023031770131",
      exposure: true,
      serverFileName: "092ae834-6b2c-4f49-afeb-ab81fe230358.jpg",
      originFileName: "20230318.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/092ae834-6b2c-4f49-afeb-ab81fe230358.jpg",
      language: "KR",
    },
    {
      id: 40,
      createdAt: "2024-04-23T09:09:51.919Z",
      updatedAt: "2024-04-23T09:09:51.919Z",
      title: "2045년 이후에도 이어질 대한민국 우주탐사 여정",
      mediaCompany: "한국일보",
      link: "https://www.hankookilbo.com/News/Read/A2023031410160000516?did=NA",
      exposure: true,
      serverFileName: "df31078e-49d3-4ae7-a938-9eb2ef4efd02.jpg",
      originFileName: "20230314.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/df31078e-49d3-4ae7-a938-9eb2ef4efd02.jpg",
      language: "KR",
    },
    {
      id: 39,
      createdAt: "2024-04-23T09:05:02.876Z",
      updatedAt: "2024-04-23T09:05:02.876Z",
      title: '尹 "우주경제 시대 열어가자"…우주개척자들과 간담회',
      mediaCompany: "노컷뉴스",
      link: "https://www.nocutnews.co.kr/news/5898953",
      exposure: true,
      serverFileName: "8f7bfbdf-a74a-4a06-934e-5d90ff22a5ea.jpg",
      originFileName: "20230222.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/8f7bfbdf-a74a-4a06-934e-5d90ff22a5ea.jpg",
      language: "KR",
    },
    {
      id: 38,
      createdAt: "2024-04-23T08:57:25.867Z",
      updatedAt: "2024-04-23T08:57:25.867Z",
      title: "다누리가 달 궤도에서 찍은 지구는 우주强國 항로표지",
      mediaCompany: "신동아",
      link: "https://shindonga.donga.com/science/article/all/13/3928783/1",
      exposure: true,
      serverFileName: "0b515365-1fad-4ddb-b68a-ccb2af46a5c8.jpg",
      originFileName: "20230208.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/0b515365-1fad-4ddb-b68a-ccb2af46a5c8.jpg",
      language: "KR",
    },
    {
      id: 36,
      createdAt: "2024-04-22T15:57:43.358Z",
      updatedAt: "2024-04-22T15:57:43.358Z",
      title:
        "'우주산업 2040년 1360조 거대시장'… 되살아나는 K-우주 스타트업 투자열기",
      mediaCompany: "더스탁",
      link: "https://www.the-stock.kr/news/articleView.html?idxno=17781",
      exposure: true,
      serverFileName: "ddab2a59-b7e4-4a39-9fe2-bcd5f38537b5.jpg",
      originFileName: "20230201.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/ddab2a59-b7e4-4a39-9fe2-bcd5f38537b5.jpg",
      language: "KR",
    },
    {
      id: 35,
      createdAt: "2024-04-22T15:44:05.886Z",
      updatedAt: "2024-04-22T15:44:05.886Z",
      title: "“대세는 우주”… 미래 먹거리로 우주 점찍은 VC들",
      mediaCompany: "조선일보",
      link: "https://biz.chosun.com/stock/stock_general/2023/01/30/J6LTOIDFXNEE7ONFIQLVDDZ3EA/?utm_source=naver&utm_medium=original&utm_campaign=biz",
      exposure: true,
      serverFileName: "cd4d9d17-6d2e-4d1b-9914-de3d82e5cfa2.jpg",
      originFileName: "20230130.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/cd4d9d17-6d2e-4d1b-9914-de3d82e5cfa2.jpg",
      language: "KR",
    },
    {
      id: 34,
      createdAt: "2024-04-22T15:37:55.982Z",
      updatedAt: "2024-04-22T15:37:55.982Z",
      title: "VC가 꼽은 올해 유망 스타트업은 '로·디·이'",
      mediaCompany: "한국경제",
      link: "https://www.hankyung.com/it/article/202301194797i",
      exposure: true,
      serverFileName: "9ee3b6e8-65e1-4332-b26c-9ab165aa9d8b.jpg",
      originFileName: "20230424.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/9ee3b6e8-65e1-4332-b26c-9ab165aa9d8b.jpg",
      language: "KR",
    },
    {
      id: 33,
      createdAt: "2024-04-22T15:36:01.417Z",
      updatedAt: "2024-04-22T15:36:01.417Z",
      title: "누리호 3차·아리랑 6·7호기 발사 대기…올해도 우주강국 향해 진격",
      mediaCompany: "한국경제",
      link: "https://www.hankyung.com/it/article/2023010546531",
      exposure: true,
      serverFileName: "75a59549-caab-4136-9b2c-673a1ee200aa.jpg",
      originFileName: "20230105.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/75a59549-caab-4136-9b2c-673a1ee200aa.jpg",
      language: "KR",
    },
    {
      id: 32,
      createdAt: "2024-04-22T14:05:21.106Z",
      updatedAt: "2024-04-22T14:05:21.106Z",
      title: "BTS 130여만km 뚫고 왔다···韓 우주인터넷 '결정체'",
      mediaCompany: "헬로디디",
      link: "https://www.hellodd.com/news/articleView.html?idxno=98584",
      exposure: true,
      serverFileName: "8a42d3f0-5e8f-4647-8474-8b591020993b.jpg",
      originFileName: "20221107.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/8a42d3f0-5e8f-4647-8474-8b591020993b.jpg",
      language: "KR",
    },
    {
      id: 31,
      createdAt: "2024-04-22T14:03:17.284Z",
      updatedAt: "2024-04-22T14:03:17.284Z",
      title: "꿈을 현실로, 호모스페이스쿠스",
      mediaCompany: "매일경제TV",
      link: "https://mbnmoney.mbn.co.kr/news/view?news_no=MM1004770134",
      exposure: true,
      serverFileName: "72333587-8748-4802-b9f1-a69c9d6c3efa.jpg",
      originFileName: "20240422.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/72333587-8748-4802-b9f1-a69c9d6c3efa.jpg",
      language: "KR",
    },
    {
      id: 30,
      createdAt: "2024-04-22T13:59:34.828Z",
      updatedAt: "2024-04-22T13:59:34.828Z",
      title: "다누리호 성공 주역 '루미르', 200억 펀딩 순항",
      mediaCompany: "딜사이트",
      link: "https://dealsite.co.kr/articles/93931",
      exposure: true,
      serverFileName: "1ed424b7-a81e-473b-97b1-c73014a703d0.jpg",
      originFileName: "20221104.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/1ed424b7-a81e-473b-97b1-c73014a703d0.jpg",
      language: "KR",
    },
    {
      id: 29,
      createdAt: "2024-04-22T13:57:21.768Z",
      updatedAt: "2024-04-22T13:57:21.768Z",
      title: "도요샛, 러 발사체 대신 누리호로 쏜다",
      mediaCompany: "뉴시스",
      link: "https://www.newsis.com/view/?id=NISX20221007_0002041382&cID=13003&pID=13100",
      exposure: true,
      serverFileName: "1421aac7-5ab4-4d6c-8c39-b850eccabb47.jpg",
      originFileName: "20221007.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/1421aac7-5ab4-4d6c-8c39-b850eccabb47.jpg",
      language: "KR",
    },
    {
      id: 28,
      createdAt: "2024-04-22T13:55:43.346Z",
      updatedAt: "2024-04-22T13:55:43.346Z",
      title: "뉴스페이스, 우주 스타트업 존재감 커진다.",
      mediaCompany: "MTN",
      link: "https://www.youtube.com/watch?v=WQ3heJOJIQU",
      exposure: true,
      serverFileName: "0ed127ad-20dd-4242-b9a7-a8a17793fbee.jpg",
      originFileName: "20220830.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/0ed127ad-20dd-4242-b9a7-a8a17793fbee.jpg",
      language: "KR",
    },
    {
      id: 27,
      createdAt: "2024-04-22T13:53:16.322Z",
      updatedAt: "2024-04-22T13:53:16.322Z",
      title: '"뉴스페이스 시대, 뉴페이스 키워야...전용 벤처펀드 절실"',
      mediaCompany: "머니투데이",
      link: "https://news.mt.co.kr/mtview.php?no=2022071216343070739",
      exposure: true,
      serverFileName: "910a0653-400f-4c46-ad4d-70a11b335f8c.jpg",
      originFileName: "20220712.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/910a0653-400f-4c46-ad4d-70a11b335f8c.jpg",
      language: "KR",
    },
    {
      id: 26,
      createdAt: "2024-04-22T13:52:15.507Z",
      updatedAt: "2024-04-22T13:52:15.507Z",
      title: "달 궤도선 '다누리' 준비 완료... 한국 우주 탐사 향한 첫걸음",
      mediaCompany: "파이낸셜뉴스",
      link: "https://www.fnnews.com/news/202206061809535629",
      exposure: true,
      serverFileName: "df714573-cbf2-44ca-b2c3-aaf807939374.jpg",
      originFileName: "20220606.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/df714573-cbf2-44ca-b2c3-aaf807939374.jpg",
      language: "KR",
    },
    {
      id: 25,
      createdAt: "2024-04-22T13:51:13.726Z",
      updatedAt: "2024-04-22T13:51:13.726Z",
      title: "인하대 ‘스페이스챌린지사업’ 연구개발 기관 선정",
      mediaCompany: "뉴시스",
      link: "https://www.newsis.com/view/?id=NISX20220504_0001859036",
      exposure: true,
      serverFileName: "19b2243d-b247-4be3-9989-42eb272e0ce9.jpg",
      originFileName: "20220504.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/19b2243d-b247-4be3-9989-42eb272e0ce9.jpg",
      language: "KR",
    },
    {
      id: 24,
      createdAt: "2024-04-22T13:27:48.868Z",
      updatedAt: "2024-04-22T13:27:48.868Z",
      title:
        "‘HARMONIA ET DIFFERENTIA’, 각 분야 조화 이룬 복합 시스템 엔지니어링으로 우주산업의 새로운 미래를 제시하다",
      mediaCompany: "월간인물",
      link: "https://www.monthlypeople.com/news/articleView.html?idxno=269737#google_vignette",
      exposure: true,
      serverFileName: "66765147-eed8-4aa1-8780-7a43c2eb3a0d.jpg",
      originFileName: "20220203.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/66765147-eed8-4aa1-8780-7a43c2eb3a0d.jpg",
      language: "KR",
    },
    {
      id: 23,
      createdAt: "2024-04-22T13:25:25.089Z",
      updatedAt: "2024-04-22T13:25:25.089Z",
      title: "우주를 향해 쏴라, 근데 누가?",
      mediaCompany: "중앙일보",
      link: "https://www.joongang.co.kr/amparticle/25018563",
      exposure: true,
      serverFileName: "604d63ae-89e2-4091-a7d0-4f9fc527ca69.jpg",
      originFileName: "20211027.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/604d63ae-89e2-4091-a7d0-4f9fc527ca69.jpg",
      language: "KR",
    },
    {
      id: 22,
      createdAt: "2024-04-22T13:23:00.844Z",
      updatedAt: "2024-04-22T13:23:00.844Z",
      title: '"구름이 가려도 보인다"…국내 독자개발 수자원위성 "홍수 안녕"',
      mediaCompany: "머니투데이",
      link: "https://news.mt.co.kr/mtview.php?no=2021100411065350095",
      exposure: true,
      serverFileName: "136ec3e7-c5ef-454a-b22c-7de8fee65a7c.jpg",
      originFileName: "20211004B.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/136ec3e7-c5ef-454a-b22c-7de8fee65a7c.jpg",
      language: "KR",
    },
    {
      id: 21,
      createdAt: "2024-04-22T11:50:05.105Z",
      updatedAt: "2024-04-22T11:50:40.731Z",
      title: "수자원 위성용 고해상 영상레이다 개발…악천후에도 지상 관측",
      mediaCompany: "전자신문",
      link: "https://www.etnews.com/20211004000097",
      exposure: true,
      serverFileName: "bce09c19-4c3d-4d1f-ae8c-567ea364c294.jpg",
      originFileName: "20211004A.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/bce09c19-4c3d-4d1f-ae8c-567ea364c294.jpg",
      language: "KR",
    },
    {
      id: 20,
      createdAt: "2024-04-22T11:47:51.549Z",
      updatedAt: "2024-04-22T11:47:51.549Z",
      title: '"수재해·북한방류 예측 우리 손에"…수자원위성 개발현장 가보니',
      mediaCompany: "아주경제",
      link: "https://www.ajunews.com/view/20211004133136152",
      exposure: true,
      serverFileName: "85f3b1c5-7056-4fdb-9af8-1fdeca3aaa56.jpg",
      originFileName: "20211004.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/85f3b1c5-7056-4fdb-9af8-1fdeca3aaa56.jpg",
      language: "KR",
    },
    {
      id: 19,
      createdAt: "2024-04-22T11:46:40.674Z",
      updatedAt: "2024-04-22T11:46:40.674Z",
      title: "올해 ICT 분야 '미래 유니콘'에 선정된 15곳 어디?",
      mediaCompany: "머니투데이",
      link: "https://news.mt.co.kr/mtview.php?no=2021051713014861390",
      exposure: true,
      serverFileName: "5ea3331e-5fdd-4174-8c35-8c370d8aba51.jpg",
      originFileName: "20210517C.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/5ea3331e-5fdd-4174-8c35-8c370d8aba51.jpg",
      language: "KR",
    },
    {
      id: 17,
      createdAt: "2024-04-22T11:42:50.210Z",
      updatedAt: "2024-04-22T11:42:50.210Z",
      title: "과기정통부, 올해 ICT 분야 미래 유니콘 기업 15곳 선정",
      mediaCompany: "연합뉴스",
      link: "https://www.yna.co.kr/view/AKR20210517057700017?section=news&site=popup_newsflash",
      exposure: true,
      serverFileName: "6893961d-1a22-47a3-bee1-c8ac3b3ae907.jpg",
      originFileName: "20210517B.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/6893961d-1a22-47a3-bee1-c8ac3b3ae907.jpg",
      language: "KR",
    },
    {
      id: 16,
      createdAt: "2024-04-22T11:34:21.652Z",
      updatedAt: "2024-04-22T11:34:21.652Z",
      title: "실크로드소프트·퓨리오사AI 등 15개사, ‘미래 유니콘 기업’ 선정",
      mediaCompany: "한국경제",
      link: "https://www.hankyung.com/it/article/202105170804i",
      exposure: true,
      serverFileName: "d3a15872-0e35-45e2-986c-0bd2347cf5c8.jpg",
      originFileName: "20210517a.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/d3a15872-0e35-45e2-986c-0bd2347cf5c8.jpg",
      language: "KR",
    },
    {
      id: 15,
      createdAt: "2024-04-22T11:18:52.172Z",
      updatedAt: "2024-04-22T11:18:52.172Z",
      title: "‘제2의 센드버드’ 나올까…미래 유니콘 기업 15개 발표",
      mediaCompany: "동아사이언스",
      link: "https://www.dongascience.com/news.php?idx=46613",
      exposure: true,
      serverFileName: "ea5674ff-3fb9-44f1-87ab-2b1eec941dfa.jpg",
      originFileName: "20210517.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/ea5674ff-3fb9-44f1-87ab-2b1eec941dfa.jpg",
      language: "KR",
    },
    {
      id: 14,
      createdAt: "2024-04-22T11:02:47.393Z",
      updatedAt: "2024-04-22T11:02:47.393Z",
      title: "500㎞ 상공서도 선명…국내 개발 '차세대 중형위성 1호'",
      mediaCompany: "JTBC",
      link: "https://news.jtbc.co.kr/article/article.aspx?news_id=NB12002991",
      exposure: true,
      serverFileName: "caba0843-6e61-4a17-b08b-7db5c232bb8f.jpg",
      originFileName: "20210504.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/caba0843-6e61-4a17-b08b-7db5c232bb8f.jpg",
      language: "KR",
    },
    {
      id: 13,
      createdAt: "2024-04-22T10:59:34.217Z",
      updatedAt: "2024-04-22T10:59:34.217Z",
      title:
        "달에 인터넷 구축 추진하는 미국, 한국 달 궤도선 우주인터넷 기술에 '눈독'",
      mediaCompany: "동아사이언스",
      link: "https://www.dongascience.com/news.php?idx=45455",
      exposure: true,
      serverFileName: "d9b5ce96-866c-4ea5-9aa7-b65ec21de5f4.jpg",
      originFileName: "20210409.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/d9b5ce96-866c-4ea5-9aa7-b65ec21de5f4.jpg",
      language: "KR",
    },
    {
      id: 12,
      createdAt: "2024-04-22T10:56:54.271Z",
      updatedAt: "2024-04-22T10:56:54.271Z",
      title: "우주강국으로 가는 '달탐사 프로젝트' 국내기업이 함께 뛴다",
      mediaCompany: "파이낸셜뉴스",
      link: "https://www.fnnews.com/news/202104011339459749",
      exposure: true,
      serverFileName: "6414ff58-3ade-40e0-bf68-520ddf8e56b2.jpg",
      originFileName: "20210401.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/6414ff58-3ade-40e0-bf68-520ddf8e56b2.jpg",
      language: "KR",
    },
    {
      id: 11,
      createdAt: "2024-04-22T10:51:32.805Z",
      updatedAt: "2024-04-22T10:52:18.865Z",
      title:
        "With CAS500, South Korea launches journey toward private-led satellite development",
      mediaCompany: "SPACENEWS",
      link: "https://spacenews.com/with-cas500-south-korea-launches-journey-toward-private-led-satellite-development/",
      exposure: true,
      serverFileName: "bd7c87fd-ce87-4faa-9f13-a5819600c29d.jpg",
      originFileName: "20210323.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/bd7c87fd-ce87-4faa-9f13-a5819600c29d.jpg",
      language: "KR",
    },
    {
      id: 10,
      createdAt: "2024-04-22T10:50:16.774Z",
      updatedAt: "2024-04-22T10:50:16.774Z",
      title: "'韓 독자기술' 차세대중형위성 우주로…\"K-위성시대 열렸다\"",
      mediaCompany: "뉴시스",
      link: "https://www.newsis.com/view/?id=NISX20210322_0001379096",
      exposure: true,
      serverFileName: "8915a52f-0c97-4b76-a9e9-1c1bfd49f821.jpg",
      originFileName: "20210329.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/8915a52f-0c97-4b76-a9e9-1c1bfd49f821.jpg",
      language: "KR",
    },
    {
      id: 9,
      createdAt: "2024-04-22T10:46:01.022Z",
      updatedAt: "2024-04-22T10:46:01.022Z",
      title: "위성 수출 시대 꿈꾼다…차중위 1호 발사 성공의미",
      mediaCompany: "아이뉴스24",
      link: "https://itnews.inews24.com/view/1351560",
      exposure: true,
      serverFileName: "5fd3bd86-6114-4200-9f1f-29c3811cdbef.jpg",
      originFileName: "20210322.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/5fd3bd86-6114-4200-9f1f-29c3811cdbef.jpg",
      language: "KR",
    },
    {
      id: 8,
      createdAt: "2024-04-22T10:39:06.812Z",
      updatedAt: "2024-04-22T10:39:06.812Z",
      title: "\"위성 찍어내고 군집까지···韓 뉴스페이스 도약 '최적기'\"",
      mediaCompany: "헬로디디",
      link: "https://www.hellodd.com/news/articleView.html?idxno=92065",
      exposure: true,
      serverFileName: "07781c91-1030-4f98-b7aa-801803869e06.jpg",
      originFileName: "20210316.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/07781c91-1030-4f98-b7aa-801803869e06.jpg",
      language: "KR",
    },
    {
      id: 7,
      createdAt: "2024-04-22T10:36:33.537Z",
      updatedAt: "2024-04-22T10:36:33.537Z",
      title: "차세대중형위성 1호 발사 D-7…가성비 甲 'K-위성' 시대 연다",
      mediaCompany: "아시아경제",
      link: "https://www.asiae.co.kr/article/2021031308332034411",
      exposure: true,
      serverFileName: "32299ef7-7aa9-46d5-9d21-57df8e9fbdac.jpg",
      originFileName: "20210313.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/32299ef7-7aa9-46d5-9d21-57df8e9fbdac.jpg",
      language: "KR",
    },
    {
      id: 6,
      createdAt: "2024-04-22T10:31:57.706Z",
      updatedAt: "2024-04-22T10:31:57.706Z",
      title: "위성도 자판기로?···국산 플랫폼 '차세대중형위성 1호' ",
      mediaCompany: "헬로디디",
      link: "https://www.hellodd.com/news/articleView.html?idxno=91490",
      exposure: true,
      serverFileName: "4ee0618d-0f89-43d6-9a6b-449657d7bc50.jpg",
      originFileName: "20210124.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/4ee0618d-0f89-43d6-9a6b-449657d7bc50.jpg",
      language: "KR",
    },
    {
      id: 5,
      createdAt: "2024-04-22T09:56:46.130Z",
      updatedAt: "2024-04-22T09:56:46.130Z",
      title: "'차세대중형위성 1호' 발사장으로 출발…3월 20일 발사",
      mediaCompany: "한국일보",
      link: "https://www.hankookilbo.com/News/Read/A2021012211140003677",
      exposure: true,
      serverFileName: "8da32bef-1897-4b69-a0f1-7d43f94457f8.jpg",
      originFileName: "20210122.jpg",
      fileUrl:
        "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/8da32bef-1897-4b69-a0f1-7d43f94457f8.jpg",
      language: "KR",
    },
  ],
};

/**
 * 페이지네이션 정보와 현재 페이지 데이터를 계산하는 함수
 * @param {number} pageNo - 현재 페이지 번호
 * @param {number} pageSize - 한 페이지당 아이템 수
 * @param {string} keyword - 검색 키워드 (옵션)
 * @param {string} language - 언어 설정 (옵션)
 * @returns {Object} 페이지네이션 정보와 현재 페이지 데이터
 */
export const calculatePagination = ({
  pageNo,
  pageSize,
  keyword = "",
  language = "",
}) => {
  // 문자열로 입력된 경우 숫자로 변환
  pageSize = parseInt(pageSize);
  pageNo = parseInt(pageNo);

  // 키워드와 언어에 따라 아이템 필터링
  let filteredItems = data.items.filter((item) => {
    const matchesKeyword =
      !keyword || item.title.toLowerCase().includes(keyword.toLowerCase());
    const matchesLanguage = !language || item.language === language;
    return matchesKeyword && matchesLanguage;
  });

  const totalCount = filteredItems.length;

  // 총 페이지 수 계산
  const totalPage = Math.ceil(totalCount / pageSize);

  // 현재 페이지가 유효한 범위 내에 있는지 확인
  pageNo = Math.max(1, Math.min(pageNo, totalPage));

  // 현재 페이지의 시작 인덱스와 끝 인덱스 계산
  const startIndex = (pageNo - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, totalCount);

  // 현재 페이지의 아이템들
  const currentPageItems = filteredItems.slice(startIndex, endIndex);

  return {
    pageSize,
    totalCount,
    totalPage,
    pageNo,
    isLast: pageNo === totalPage,
    isFirst: pageNo === 1,
    items: currentPageItems,
    keyword,
    language,
  };
};
