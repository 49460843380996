import { route } from "../../router/route";
import { root } from "../../styles/GlobalStyle";

//main intro

const MENU_BG_COLOR = {
  SATELLITE_SYSTEM: root.businessPurple,
  SATELLITE_MOUNTED_DEVICE: root.businessBlue,
  ELECTRIC_GROUND: root.businessNavy,
  CIVILIAN_DEVICE: root.businessSky,
};

export const intro = {
  bgImage: "/assets/imgs/renewal/business/main/main-bg2.webp",
  bgIcon: "/assets/imgs/renewal/business/main/미낭림.webp",

  KR: {
    header: "우리의 비즈니스 여정을 소개합니다.",
    bigTexts: ["대한민국", "지구관측", "인공위성", "전문기업"],
    description: {
      title: "Who we are",
      contents: [
        [
          "대한민국 우주시대의 개막",
          "한국형 발사체 ‘누리호’ 3차 발사의 성공",
          "한국 최초의 달 궤도선 ‘다누리호’의 임무수행 성공 등",
          "루미르는 대한민국 우주산업 성공의 역사 속에서",
          "오랜시간 함께 걸어왔습니다.",
        ],
        [
          "다수의 국가 우주개발 사업을 수주하며",
          "국가로부터 인정받은 우주개발 헤리티지를 기반으로",
          "New Space 시대에 발맞춰 우주의 상업적 이용을 목표로",
          "지구관측 인공위성 분야의 전문기업으로 성장해 나가고자 합니다.",
        ],
      ],
    },
  },
  EN: {
    header: "Introducing our business journey.",
    bigTexts: ["Korea's", "Earth Observation", "Satellite", "Specialist"],
    description: {
      title: "Who we are",
      contents: [
        [
          "The opening of the Korean space age",
          "The successful launch of the Korean launch vehicle 'Nuriho' 3rd launch",
          "The successful mission of Korea's first lunar orbiter Danuri (KPLO, The Korea Pathfinder Lunar Orbiter)",
          "Lumir has been walking together for a long time",
          "in the history of the success of the Korean space industry.",
        ],
        [
          "By winning numerous national space development projects",
          "Based on the space development heritage recognized by the country",
          "In line with the New Space era, we aim to use space commercially",
          "We aim to grow into a specialist in the field of Earth observation satellites.",
        ],
      ],
    },
  },
};

// menuList
const IMG_LIST = {
  LUMIR_X: "/assets//imgs/renewal/business/main/webp/LumirX.webp",
  CAS500_5: "/assets/imgs/renewal/business/main/webp/CAS500-5.webp",
  CATIS: "/assets/imgs/renewal/business/main/webp/CATIS.webp",
  KaPA: "/assets/imgs/renewal/business/main/webp/KaPA.webp",
  DTNPL: "/assets/imgs/renewal/business/main/webp/DTNPL.webp",
  IDHU: "/assets/imgs/renewal/business/main/webp/IDHU.webp",
  OBC: "/assets/imgs/renewal/business/main/webp/OBC.webp",
  CMGC: "/assets/imgs/renewal/business/main/webp/CMGC.webp",
  XDM_EGSE: "/assets/imgs/renewal/business/main/webp/XDM-EGSE.webp",
  K7P_EGSE: "/assets/imgs/renewal/business/main/webp/K7P-EGSE.webp",
  GAVS: "/assets/imgs/renewal/business/main/webp/GAVS.webp",
};

export const menus = {
  KR: {
    header: "우리의 비즈니스 여정을 소개합니다.",
    system: {
      title: "위성시스템",
      description:
        "루미르는 세계적으로 극소수에 불과한 주야간 전천후 지구관측 가능한 초소형 SAR 위성시스템 전문 기업입니다.",
      menuList: [
        { title: "LumirX", link: route.lumirX_renewal, img: IMG_LIST.LUMIR_X },
        {
          title: "CAS500-5",
          link: route.cas500_5_renewal,
          img: IMG_LIST.CAS500_5,
        },
      ],
      bgColor: MENU_BG_COLOR.SATELLITE_SYSTEM,
      hash: route.satellite_system,
    },
    mountedDevice: {
      title: "위성탑재장치",
      description:
        "루미르는 차세대중형위성 시리즈에 탑재된 영상자료처리장치와 위성 내 모든 전장품을 제어하는 탑재컴퓨터,<br/>그리고 달탐사선 다누리호에 탑재된 우주인터넷 통신장치 등 대한민국의 많은 인공위성 전장품들을 최초로 국산화하였습니다. ",
      menuList: [
        { title: "CATIS™", link: route.catis_renewal, img: IMG_LIST.CATIS },
        { title: "KAPA", link: route.KaPA_renewal, img: IMG_LIST.KaPA },
        { title: "DTNPL", link: route.DTNPL_renewal, img: IMG_LIST.DTNPL },
        { title: "IDHU", link: route.IDHU_renewal, img: IMG_LIST.IDHU },
        { title: "OBC", link: route.OBC_renewal, img: IMG_LIST.OBC },
        { title: "CMGC", link: route.CMGC_renewal, img: IMG_LIST.CMGC },
      ],
      bgColor: MENU_BG_COLOR.SATELLITE_MOUNTED_DEVICE,
      hash: route.satellite_mounted_device,
    },
    electric: {
      title: "전기지상지원장비",
      description:
        "전기지상지원장비(EGSE: Electrical Ground Support Equipment)는 위성을 구성하는 각종 전자장치 및 위성체의 개발 과정에서 기능을 시험하고, 성능을 검증하기 위한 시험을 지원하는 장비입니다. 루미르는 차세대중형위성 시리즈의 X-band 하향링크모듈, 영상자료처리장치, 위성의 전력계 등 다양한 종류의 전기지상지원장비(EGSE)를 개발하고 있습니다.",
      menuList: [
        {
          title: "XDM-EGSE",
          link: route.XDM_EGSE_renewal,
          img: IMG_LIST.XDM_EGSE,
        },
        {
          title: "K7P-EGSE",
          link: route.K7P_EGSE_renewal,
          img: IMG_LIST.K7P_EGSE,
        },
      ],
      bgColor: MENU_BG_COLOR.ELECTRIC_GROUND,
      hash: route.electric_ground,
    },
    minsu: {
      title: "민수장치",
      description:
        "루미르는 인공위성 장치 개발에 적용되는 전자기 해석 기술을 적용하여, 대형 선박 엔진 연료제어 밸브용 솔레노이드를 개발하였습니다.",
      menuList: [
        {
          title: "GAVS",
          link: route.GAVS_renewal,
          img: IMG_LIST.GAVS,
        },
      ],
      bgColor: MENU_BG_COLOR.CIVILIAN_DEVICE,
      hash: route.civilian_device,
    },
  },
  EN: {
    header: "Introducing our business journey.",
    system: {
      title: "Satellite System",
      description:
        "Lumir is a specialist in ultra-small SAR satellite systems that can observe the entire Earth day and night, which is only a handful of companies worldwide.",
      menuList: [
        { title: "LumirX", link: route.lumirX_renewal, img: IMG_LIST.LUMIR_X },
        {
          title: "CAS500-5",
          link: route.cas500_5_renewal,
          img: IMG_LIST.CAS500_5,
        },
      ],
      bgColor: MENU_BG_COLOR.SATELLITE_SYSTEM,
      hash: route.satellite_system,
    },
    mountedDevice: {
      title: "Satellite Mounted Device",
      description:
        "Lumir was the first in Korea to localize many of Korea's artificial satellite components, such as the image data processing device mounted on the CAS500 series and the onboard computer that controls all the components inside the satellite.",
      menuList: [
        { title: "CATIS™", link: route.catis_renewal, img: IMG_LIST.CATIS },
        { title: "KAPA", link: route.KaPA_renewal, img: IMG_LIST.KaPA },
        { title: "DTNPL", link: route.DTNPL_renewal, img: IMG_LIST.DTNPL },
        { title: "IDHU", link: route.IDHU_renewal, img: IMG_LIST.IDHU },
        { title: "OBC", link: route.OBC_renewal, img: IMG_LIST.OBC },
        { title: "CMGC", link: route.CMGC_renewal, img: IMG_LIST.CMGC },
      ],
      bgColor: MENU_BG_COLOR.SATELLITE_MOUNTED_DEVICE,
      hash: route.satellite_mounted_device,
    },
    electric: {
      title: "Electrical Ground Support Equipment",
      description:
        "Electrical Ground Support Equipment (EGSE) is equipment that supports testing the functions and verifying the performance of various electronic devices and satellite bodies during the development process of the satellite. Lumir is developing various types of EGSE, such as the X-band downlink module, image data processing device, and satellite power meter for the CAS500 series.",
      menuList: [
        {
          title: "XDM-EGSE",
          link: route.XDM_EGSE_renewal,
          img: IMG_LIST.XDM_EGSE,
        },
        {
          title: "K7P-EGSE",
          link: route.K7P_EGSE_renewal,
          img: IMG_LIST.K7P_EGSE,
        },
      ],
      bgColor: MENU_BG_COLOR.ELECTRIC_GROUND,
      hash: route.electric_ground,
    },
    minsu: {
      title: "Civilian Device",
      description:
        "Lumir has developed a solenoid for large ship engine fuel control valves using electromagnetic analysis technology applied to artificial satellite device development.",
      menuList: [
        {
          title: "GAVS",
          link: route.GAVS_renewal,
          img: IMG_LIST.GAVS,
        },
      ],
      bgColor: MENU_BG_COLOR.CIVILIAN_DEVICE,
      hash: route.civilian_device,
    },
  },
};

//LumirX

export const lumirX = {
  KR: {
    header: "초고해상도 초소형 SAR 위성",
    bgColor: MENU_BG_COLOR.SATELLITE_SYSTEM,
    pdf: {
      src: "/assets/pdfs/LumirX.pdf",
      downloadFileName: "[LUMIR] LumirX Brochure",
    },
    bigImg: [
      {
        title: "초고해상도 초소형 SAR 군집위성 시스템",
        content:
          "루미르 초소형 SAR 군집위성 시스템은 총 18기로 구성하여 지구 전역의 주요 관심지역의 영상을 1시간 이내에 촬영하고, 고객에 영상 및 정보를 제공합니다. 이러한 실시간에 근접하는 처리속도는 감시정찰, 재난재해 등 긴급하게 정보 취득을 요하는 분야에 매우 유용한 정보를 제공할 것 입니다. LumirX 체계의 신호 및 영상처리 플랫폼인 SARDIP을 통해 고객은 LumirX의 SAR 영상을 검색 및 구매할 수 있으며 루미르가 제공하는 다양한 분석 솔루션을 쉽게 활용할 수 있습니다. ",
        image: "/assets/imgs/renewal/business/LumirX/main.webp",
      },
    ],
    feature: [
      {
        title: "세계 최고 수준의 초고해상도",
        content:
          "레이다 신호 대역폭 1200 MHz 를 통해 경사거리 0.125 m, 지상거리 0.3 m의 세계 최고 수준의 초고해상도 영상을 제공합니다. ",
      },
      {
        title: "소형화 + 경량화",
        content:
          "SoC 기술의 RF장치와 디지털 장치 일원화 및 HMIC 공정기술로 집적화한 TRM을 평판형 안테나와 일체화하여 소형화 및 경량화를 구현하였습니다. ",
      },
      {
        title: "대용량 저장공간",
        content:
          "16 Tbits의 대용량 영상데이터 저장능력은 국내 실용급 위성인 다목적실용위성 6호의 저장용량과 비교할 때 16배 향상된 성능을 자랑합니다.",
      },
      {
        title: "초고속 데이터 다운링크",
        content:
          "고속 데이터 전송을 위한 CCSDS SCCC 방식의 변조 기능을 구현하여 2 Gbps의 초고속 데이터 다운링크 성능을 유지합니다.",
      },
      {
        title: "4가지 다양한 관측모드",
        content:
          "0.3 m 초고해상도 모드, 0.7 m 고해상도 모드, 1 m 표준해상도 모드, 10 m 해상도로 100 km 이상 관측폭을 갖는 광역모드까지 지원하여 사용자의 다양한 요구에 부합하는 영상을 제공합니다.",
      },
    ],
    desctiptImg: [
      {
        title: "LumirX의 제원",
        src: "/assets/imgs/renewal/business/LumirX/item.webp",
      },
      {
        title: "Load Map",
        src: "/assets/imgs/renewal/business/LumirX/loadmap.webp",
      },
    ],
  },
  EN: {
    header: "Ultra-high-resolution ultra-small SAR cluster satellite system",
    bgColor: MENU_BG_COLOR.SATELLITE_SYSTEM,
    pdf: {
      src: "/assets/pdfs/LumirX.pdf",
      downloadFileName: "[LUMIR] LumirX Brochure",
    },
    bigImg: [
      {
        title: "Ultra-high-resolution ultra-small SAR cluster satellite system",
        content:
          "Lumir's ultra-small SAR cluster satellite system consists of a total of 18 units, which can capture images of major areas of interest around the world within an hour and provide images and information to customers. This real-time processing speed is expected to provide very useful information in fields that require urgent information acquisition such as surveillance and disaster relief. Through SARDIP, the signal and image processing platform of the LumirX system, customers can search for and purchase SAR images of LumirX and easily utilize various analysis solutions provided by Lumir.",
        image: "/assets/imgs/renewal/business/LumirX/main.webp",
      },
    ],
    feature: [
      {
        title: "World-class ultra-high resolution",
        content:
          "It provides the world's highest level of ultra-high-resolution images with a radar signal bandwidth of 1200 MHz, providing a ground distance of 0.125 m and a ground distance of 0.3 m.",
      },
      {
        title: "Miniaturization + Lightweight",
        content:
          "It has implemented miniaturization and lightweight by integrating TRM integrated with SoC technology RF devices and digital devices into a flat antenna and integrating them.",
      },
      {
        title: "Large storage space",
        content:
          "The large-capacity image data storage capacity of 16 Tbits boasts a 16-fold improvement in performance compared to the storage capacity of the domestic practical satellite, the multipurpose practical satellite 6.",
      },
      {
        title: "Ultra-high-speed data downlink",
        content:
          "It maintains a high-speed data downlink performance of 2 Gbps by implementing a modulation function using the CCSDS SCCC method for high-speed data transmission.",
      },
      {
        title: "4 different observation modes",
        content:
          "It supports various images that meet the diverse needs of users, such as 0.3 m ultra-high-resolution mode, 0.7 m high-resolution mode, 1 m standard resolution mode, and wide mode with a resolution of 10 m and an observation width of more than 100 km.",
      },
    ],
    desctiptImg: [
      {
        title: "Specifications of LumirX",
        src: "/assets/imgs/renewal/business/LumirX/item.webp",
      },
      {
        title: "Load Map",
        src: "/assets/imgs/renewal/business/LumirX/loadmap.webp",
      },
    ],
  },
};

//cas500-5
export const cas500_5 = {
  KR: {
    header: "차세대중형위성 5호",
    bgColor: MENU_BG_COLOR.SATELLITE_SYSTEM,
    bigImg: [
      {
        title: "차세대중형위성 5호 C-Band SAR 위성시스템",
        content:
          "환경부에서 주로 활용할 차세대중형위성 5호는 수자원 조사, 하천관리, 해양환경 감시, 재난·재해 대응용 위성으로 C-Band SAR를 탑재하는 500 kg급 중형위성입니다. 루미르는 임무탑재체 전체에 해당되는 레이다부체계(C-SAR) 및 자료전송부체계(PDTS) 전체 개발을 담당하고 있습니다. 차세대중형위성 5호는 우리나라가 100% 독자적인 기술로 개발한 위성을 우리나라가 개발한 누리호 5차 발사체를 이용하여 발사될 예정입니다.",
        image: "/assets/imgs/renewal/business/Cas5005-5/main.webp",
      },
    ],
    feature: [
      {
        title: "국내 최초 독자 모델",
        content:
          "차세대중형위성 5호의 SAR 시스템은 대한민국 인공위성 SAR 시스템으로써는 국내 최초의 독자 모델로서 개발됩니다. (타 위성의 경우 해외 도입 모델)",
      },
      {
        title: "국내 최초 메쉬 타입 안테나",
        content:
          "국내에서는 최초로 C-Band(5.4 GHz) 메쉬(mesh) 타입 반사판 안테나를 적용하게 됩니다.",
      },
      {
        title: "비선형 왜곡 제거",
        content:
          "C-Band 주파수를 직접 생성 및 획득하여 RF소자의 비선형 왜곡 제거",
      },
      {
        title: "다채널 / 다중 주파수",
        content: "다채널 / 다중 주파수 동시 운용으로 관측 성능 확대",
      },
      {
        title: "소형화 + 경량화",
        content:
          "최신 우주기술을 적용한 소형화 및 경량화로 임무탑재체 전체 목표 무게를 150 kg 이하로 구현합니다.",
      },
    ],
    desctiptImg: [
      {
        title: "CAS500-5의 제원",
        src: "/assets/imgs/renewal/business/Cas5005-5/item.webp",
      },
    ],
  },
  EN: {
    header: "Compact Advanced Satellite No. 5",
    bgColor: MENU_BG_COLOR.SATELLITE_SYSTEM,
    bigImg: [
      {
        title: "CAS500-5 C-Band SAR satellite system",
        content:
          "CAS500-5, which will be mainly used by the Ministry of Environment, is a medium-sized satellite weighing 500 kg equipped with a C-Band SAR for water resource survey, river management, marine environmental monitoring, and disaster response. Lumir is responsible for the development of the entire radar system (C-SAR) and data transmission system (PDTS) for the mission payload. CAS500-5 will be launched using Korea's independently developed satellite and Korea's developed Nuriho 5th launch vehicle.",
        image: "/assets/imgs/renewal/business/Cas5005-5/main.webp",
      },
    ],
    feature: [
      {
        title: "First domestic independent model",
        content:
          "The SAR system of CAS500-5 is developed as the first domestic independent model of Korea's artificial satellite SAR system. (In the case of other satellites, it is a model introduced from overseas)",
      },
      {
        title: "First domestic mesh type antenna",
        content:
          "For the first time in Korea, a C-Band (5.4 GHz) mesh type reflector antenna will be applied.",
      },
      {
        title: "Nonlinear distortion removal",
        content:
          "Directly generate and acquire C-Band frequency to remove nonlinear distortion of RF components",
      },
      {
        title: "Multi-channel / multi-frequency",
        content:
          "Expand observation performance through multi-channel / multi-frequency simultaneous operation",
      },
      {
        title: "Miniaturization + Lightweight",
        content:
          "Implement the entire mission payload target weight of 150 kg or less through miniaturization and lightweight using the latest space technology.",
      },
    ],
    desctiptImg: [
      {
        title: "Specifications of CAS500-5",
        src: "/assets/imgs/renewal/business/Cas5005-5/item.webp",
      },
    ],
  },
};

//catis

export const catis = {
  KR: {
    header: "초고해상도 초소형 SAR 장치",
    bgColor: MENU_BG_COLOR.SATELLITE_MOUNTED_DEVICE,
    bigImg: [
      {
        title: "초고해상도 초소형 SAR 탑재체 CATIS™",
        content:
          "Compact Any-Time Imaging System<br/><br/>CATIS™는 자체개발한 초고해상도 초소형 인공위성 영상레이다 장치로 레이다 신호 송수신 장치 SCEU, 능동 위상배열 안테나 SATA, 데이터 다운링크 안테나 KaPA의 3가지 구성품으로 이루어집니다. 0.3 m 초고해상도 150 kg 급 초소형 인공위성 탑재용 X-Band 영상레이다 장치이며, 고도 500 km의 저궤도를 돌며 관측하고자 하는 영역에 광대역 RF 신호를 방사하고 반사되는 신호를 수신하여 지구관측 데이터를 획득하는 장치입니다. ",
        image: "/assets/imgs/renewal/business/Catis/main.webp",
      },
    ],
    devices: [
      {
        title: "레이다 신호 송수신 장치 SCEU",
        content:
          "SAR Control Electronics Unit<br/><br/>SAR 제어 전자장치인 SCEU는 CATIS™ 시스템을 제어하며, 첩 신호 발생 및 송신, SAR 신호의 디지털 수신, 데이터 BAQ 압축, 그리고 타이밍 제어 등의 기능을 포함합니다. 또한 대용량 영상 데이터 저장 및 고속 적응형 변조기법 (ACM: Adaptive Coding & Modulation) 기법의 지상 데이터 전송 기능을 지원합니다.",
        image: "/assets/imgs/renewal/business/Catis/sceu.webp",
        descriptions: [
          "1,200 MHz 의 광대역 신호 생성 기능",
          "16 Tbit 대용량 저장 기능",
          "2 Gbps 고속 데이터 전송을 위한 CCSDS SCCC 방식의 변조 기능",
          "3U PCB 사이즈 소형경량화 구현",
        ],
      },
      {
        title: "능동 위상배열 안테나 SATA",
        content:
          "SAR Antenna & TRM Assembly<br/><br/>SATA는 SAR 안테나 조립체이며, HMIC 기술 기반 TRM 집적화와 이중대역, 이중편파 특성의 평판형 위상배열안테나로서 소형/경량화를 구현하여 초소형위성에 탑재에 적합합니다. 전자식 빔 조향 기능을 보유하여 관측폭을 확대 조절할 수 있습니다.",
        image: "/assets/imgs/renewal/business/Catis/sata.webp",
        descriptions: [
          "1,200 MHz 의 광대역 대역폭 특성 구현",
          "3.2 kW 고출력 증폭 및 빔 조향 구현",
        ],
      },
      {
        title: "데이터 다운링크 안테나 KaPA",
        content:
          "Ka-band Transmitter and<br/>Phased Array Antenna<br/><br/>KaPA는 Ka-Band에서 1,500 MHz의 대역폭으로 2 Gbps의 전송속도로 지구관측 데이터를 전송할 수 있습니다. 아울러 능동적 전기적인 전자파 빔조향 기능으로 지상국 방향으로 데이터 전송용 전자파 빔을 지향할 수 있으므로, 지상국과 데이터 통신을 위한 위성의 자세 지향 구동의 필요가 없이 신속한 통신을 할 수가 있습니다.",
        image: "/assets/imgs/renewal/business/Catis/kapa.webp",
        descriptions: [
          "± 60°의 광각 빔 조향 구현",
          "66.6 dBm의 고출력 EIRP 구현",
        ],
      },
    ],
  },
  EN: {
    header: "Ultra-high-resolution ultra-small SAR device",
    bgColor: MENU_BG_COLOR.SATELLITE_MOUNTED_DEVICE,
    bigImg: [
      {
        title: "Ultra-high-resolution ultra-small SAR device CATIS™",
        content:
          "Compact Any-Time Imaging System<br/><br/>CATIS™ is a self-developed ultra-high-resolution ultra-small artificial satellite image radar device consisting of three components of radar signal transceiver device SCEU, active phased array antenna SATA, and data downlink antenna KaPA. It is an X-Band image radar device for ultra-small artificial satellites weighing 150 kg with ultra-high resolution of 0.3 m, which emits a wideband RF signal to the area to be observed while orbiting at an altitude of 500 km and receives the reflected signal to acquire Earth observation data.",
        image: "/assets/imgs/renewal/business/Catis/main.webp",
      },
    ],
    devices: [
      {
        title: "SAR Control Electronics Unit SCEU",
        content:
          "SAR Control Electronics Unit<br/><br/>The SAR Control Electronics Unit (SCEU) controls the CATIS™ system and includes functions such as chirp signal generation and transmission, digital reception of SAR signals, data BAQ compression, and timing control. It also supports high-capacity image data storage and high-speed adaptive modulation (ACM) for ground data transmission.",
        image: "/assets/imgs/renewal/business/Catis/sceu.webp",
        descriptions: [
          "Wideband signal generation function of 1,200 MHz",
          "Large-capacity storage function of 16 Tbit",
          "Modulation function using CCSDS SCCC method for high-speed data transmission of 2 Gbps",
          "Miniaturization and lightweight implementation of 3U PCB size",
        ],
      },
      {
        title: "SAR Antenna & TRM Assembly SATA",
        content:
          "SAR Antenna & TRM Assembly<br/><br/>The SAR Antenna & TRM Assembly (SATA) is a SAR antenna assembly that implements miniaturization and lightweight by integrating TRM based on HMIC technology and a flat phased array antenna with dual-band, dual-polarization characteristics, making it suitable for mounting on ultra-small satellites. It has an electronic beam steering function that can expand and adjust the observation width.",
        image: "/assets/imgs/renewal/business/Catis/sata.webp",
        descriptions: [
          "Implementation of wideband bandwidth characteristics of 1,200 MHz",
          "Implementation of 3.2 kW high-power amplification and beam steering",
        ],
      },
      {
        title: "Ka-band Transmitter and Phased Array Antenna KaPA",
        content:
          "Ka-band Transmitter and Phased Array Antenna<br/><br/>KaPA can transmit Earth observation data at a speed of 2 Gbps with a bandwidth of 1,500 MHz in the Ka-Band. In addition, it can transmit data to the ground station in the direction of the ground station using an active electrical beam steering function, so it can communicate quickly without the need for precise orientation of the satellite for communication with the ground station.",
        image: "/assets/imgs/renewal/business/Catis/kapa.webp",
        descriptions: [
          "Implementation of wide-angle beam steering of ± 60°",
          "Implementation of high-power EIRP of 66.6 dBm",
        ],
      },
    ],
  },
};

// kapa

export const kapa = {
  KR: {
    pdf: {
      src: "/assets/pdfs/KaPA.pdf",
      downloadFileName:
        "[LUMIR] Ka-band Transmitter and Phased Array Antenna Brochure",
    },
    header: "Ka-band<br/>송신기 및 능동형 어레이 안테나",
    bgColor: MENU_BG_COLOR.SATELLITE_MOUNTED_DEVICE,
    bigImg: [
      {
        title: "Ka-band 송신기 및 Ka band 능동형 어레이 안테나",
        content:
          "Ka-band Transmitter and Ka-band Phased Array Antenna <br/><br/>실용위성의 Ka-band 다운링크 시스템의 구성품으로 전자 빔 조향 안테나 기술 및 소프트웨어 정의 라디오 기술이 적용된 고기능의 Ka-band 안테나 및 Ka-band 데이터 전송 장치입니다. 소형·경량화 및 단일 유닛 모듈화를 통해 시스템 최적화를 구현하였으며, 4 Gbps 이상의 고속 데이터 전송이 필요한 위성 탑재체 PDTS(Payload Data Transmission Subsystem)의 핵심 구성품입니다. ",
        image: "/assets/imgs/renewal/business/Kapa/main.webp",
      },
    ],
    feature: [
      { title: "최대 12 Gbps 고속 데이터 전송률" },
      { title: "최적화된 통합 유닛 모듈러 시스템" },
      { title: "LHCP 및 RHCP 동시 운영" },
      { title: "< 400 W 저전력 소비" },
      { title: "< 15 kg 경량 디자인" },
    ],
    desctiptImg: [
      {
        title: "KaPA의 제원",
        src: "/assets/imgs/renewal/business/Kapa/item.webp",
      },
    ],
  },
  EN: {
    pdf: {
      src: "/assets/pdfs/KAPA.pdf",
      downloadFileName:
        "[LUMIR] Ka-band Transmitter and Phased Array Antenna Brochure",
    },
    header: "Ka-band Transmitter and Phased Array Antenna",
    bgColor: MENU_BG_COLOR.SATELLITE_MOUNTED_DEVICE,
    bigImg: [
      {
        title: "Ka-band Transmitter and Ka-band Phased Array Antenna",
        content:
          "Ka-band Transmitter and Ka-band Phased Array Antenna <br/><br/>It is a high-performance Ka-band antenna and Ka-band data transmission device that applies electronic beam steering antenna technology and software-defined radio technology to the Ka-band downlink system of practical satellites. It is a key component of the PDTS (Payload Data Transmission Subsystem) of the satellite payload that requires high-speed data transmission of 4 Gbps or more through miniaturization, lightweight, and single-unit modularization to implement system optimization.",
        image: "/assets/imgs/renewal/business/Kapa/main.webp",
      },
    ],
    feature: [
      { title: "Maximum 12 Gbps high-speed data transmission rate" },
      { title: "Optimized integrated unit modular system" },
      { title: "Simultaneous operation of LHCP and RHCP" },
      { title: "< 400 W low power consumption" },
      { title: "< 15 kg lightweight design" },
    ],
    desctiptImg: [
      {
        title: "Specifications of KAPA",
        src: "/assets/imgs/renewal/business/Kapa/item.webp",
      },
    ],
  },
};

//dtnpl
export const dtnpl = {
  KR: {
    header: "달 탐사용 우주인터넷 통신장치",
    bgColor: MENU_BG_COLOR.SATELLITE_MOUNTED_DEVICE,
    bigImg: [
      {
        title: "달 탐사용 우주인터넷 통신장치",
        content:
          "Delay-Tolerant Network Payload<br/><br/>달 탐사용 우주인터넷 통신장치는 지구와 달 사이의 초장거리에서 통신이 두절되기 쉬운 상황에서 지연허용 네트워크 방식을 이용하여 우주인터넷을 구현하는 장치입니다. 루미르는 우리나라 첫 달 탐사선인 ‘다누리호’에 전자통신연구원(ETRI)과 달 탐사용 우주인터넷 통신장치를 개발하여 탑재하였습니다. 루미르의 달 탐사용 우주인터넷 통신장치는 달에서 지구까지 BTS 뮤직비디오를 수신하는 시험을 성공적으로 수행함으로써 TRL 9을 달성하였습니다.",
        image: "/assets/imgs/renewal/business/Dtnpl/main.webp",
      },
    ],
    feature: [
      {
        title: "TRL 9 달성",
        content:
          "다누리호를 통해 성공적으로 발사되었고, 임무 목적상 통신 거리(약 38만 km)보다 약 3배 이상 떨어진 거리인 라그랑주 포인트에서 사진·영상 데이터를 지구로 전송하는 데 성공하였습니다.",
      },
      {
        title: "NASA의 ION Software 탑재",
        content:
          "SoC 기술의 RF장치와 디지털 장치 일원화 및 HMIC 공정기술로 집적화한 TRM을 평판형 안테나와 일체화하여 소형화 및 경량화를 구현하였습니다. ",
      },
      {
        title: "소형화 + 경량화",
        content:
          "차세대 우주용 핵심소자인 RTG4 FPGA 기반 SoC(System on Chip) 기술을 적용 Single Board Computer로 개발하여 소형화 및 800 g 초경량으로 개발하였습니다.",
      },
    ],
    wideImg: {
      title: "DTN 네트워크 구성도",
      src: "/assets/imgs/renewal/business/Dtnpl/network.webp",
    },
    desctiptImg: [
      {
        title: "DTNPL의 제원",
        src: "/assets/imgs/renewal/business/Dtnpl/item.webp",
      },
    ],
  },
  EN: {
    header: "Space Internet Communication Device for Lunar Exploration",
    bgColor: MENU_BG_COLOR.SATELLITE_MOUNTED_DEVICE,
    bigImg: [
      {
        title: "Space Internet Communication Device for Lunar Exploration",
        content:
          "Delay-Tolerant Network Payload<br/><br/>The Space Internet Communication Device for Lunar Exploration is a device that implements space internet using a delay-tolerant network method in situations where communication is easily interrupted in the ultra-long distance between the Earth and the Moon. Lumir developed and mounted the Space Internet Communication Device for Lunar Exploration on Korea's first lunar probe, Danuri (KPLO, The Korea Pathfinder Lunar Orbiter) in collaboration with the Electronics and Telecommunications Research Institute (ETRI). Lumir's Space Internet Communication Device for Lunar Exploration achieved TRL 9 by successfully receiving BTS music videos from the Moon to Earth.",
        image: "/assets/imgs/renewal/business/Dtnpl/main.webp",
      },
    ],
    feature: [
      {
        title: "Achieved TRL 9",
        content:
          "It was successfully launched through Danuri (KPLO, The Korea Pathfinder Lunar Orbiter) and successfully transmitted photo and video data to Earth from the Lagrange point, which is more than three times the communication distance (about 380,000 km) for mission purposes.",
      },
      {
        title: "Equipped with NASA's ION Software",
        content:
          "It has applied SoC (System on Chip) technology based on RTG4 FPGA, a next-generation space core component, to develop a Single Board Computer, miniaturizing and developing it into an ultra-lightweight of 800 g.",
      },
      {
        title: "Miniaturization + Lightweight",
        content:
          "It has implemented miniaturization and lightweight by integrating TRM based on HMIC technology and a flat antenna into a single unit.",
      },
    ],
    wideImg: {
      title: "DTNPL Configuration",
      src: "/assets/imgs/renewal/business/Dtnpl/network.webp",
    },
  },
};

//idhu

export const idhu = {
  KR: {
    header: "영상자료처리장치",
    bgColor: MENU_BG_COLOR.SATELLITE_MOUNTED_DEVICE,
    bigImg: [
      {
        title: "영상자료처리장치",
        content:
          "Image Data Handling Unit<br/><br/>위성의 영상자료처리장치는 위성에 탑재된 (SAR 또는 광학카메라 등) 지구관측 센서로부터 출력되는 고속 대용량 데이터를 실시간으로 입력 받아서 압축하여 저장하고, 암호화/부호화 처리를 하여 지상국으로 전송하는 임무탑재체를 구성하는 핵심 장치입니다. 루미르가 개발한 영상자료처리장치는 그동안 100% 수입에 의존하던 위성 탑재 장치의 첫 국산화를 달성하였으며, 해외제품 대비 압도적인 성능은 물론 비용 절감을 통해 자체 경쟁력을 확보하였습니다.",
        image: "/assets/imgs/renewal/business/Idhu/main.webp",
      },
    ],
    feature: [
      {
        title: "차세대중형위성 시리즈 양산 공급",
        content:
          "차세대중형위성 1/2/4호 사업에 참여하여 개발 및 납품 완료하였으며, 현재 5호 사업을 진행하고 있습니다. (3호기의 경우 기술 검증 위성)",
      },
      {
        title: "최초 국산화 달성 및 압도적 성능",
        content:
          "100% 수입에 의존하던 위성 탑재 장치의 첫 국산화를 달성하였으며, 해외제품 대비 압도적인 성능을 구현하였습니다.",
      },
      {
        title: "비휘발성 대용량 메모리 적용",
        content:
          "국내에서 최초로 실용급 위성의 영상자료처리장치에 비휘발성 NAND Flash 소자를 적용하여 저장 용량을 획기적으로 높였으며, 전원 공급이 중단되어도 데이터를 보존합니다.",
      },
      {
        title: "고속 데이터 처리",
        content:
          "NAND Flash 소자의 병렬 구성을 통해 고속 데이터 처리가 가능하여, 고속 데이터 송수신이 가능한 암호화 및 부호화 알고리즘을 구현하여 지상국과의 제한된 통신 시간 동안 최대한 많은 데이터의 송수신이 가능합니다",
      },
      {
        title: "대용량, 소형/경량, 저전력화",
        content:
          "중형급 위성 탑재용으로 개발된 제품으로, 1.5 Tb의 대용량화, 15 kg의 소형/경량화를 구현하였습니다. 소모전력 75 W의 저전력 제품이며, 동일 요구기능 대비 소형화, 경량화, 저전력화 측면에서 현재 세계 최고의 수준을 자랑합니다.",
      },
    ],
    middleImg: {
      title: "차세대중형위성 1호의 지구관측 영상",
      images: [
        {
          image: "/assets/imgs/renewal/business/Idhu/dokdo.webp",
          text: "독도 (2021. 3. 31)",
        },
        {
          image:
            "/assets/imgs/renewal/business/Idhu/jamsil-sports-complex.webp",
          text: "서울, 잠실 종합운동장 (2021. 04. 08)",
        },
      ],
    },
    desctiptImg: [
      {
        title: "IDHU의 제원",
        src: "/assets/imgs/renewal/business/Idhu/item.webp",
      },
    ],
  },
  EN: {
    header: "Image Data Handling Unit",
    bgColor: MENU_BG_COLOR.SATELLITE_MOUNTED_DEVICE,
    bigImg: [
      {
        title: "Image Data Handling Unit",
        content:
          "The image data processing device of the satellite is a core device that receives high-speed large-capacity data output from the Earth observation sensor mounted on the satellite (SAR or optical camera, etc.), compresses and stores it in real-time, and encrypts/encodes it for transmission to the ground station. Lumir has achieved the first localization of satellite-mounted devices that have been 100% dependent on imports, securing its competitiveness through overwhelming performance and cost savings compared to foreign products.",
        image: "/assets/imgs/renewal/business/Idhu/main.webp",
      },
    ],
    feature: [
      {
        title: "Mass production and supply of the CAS500 series",
        content:
          "Participated in the development and delivery of the CAS500-1/2/4 projects and is currently working on the 5th project. (The 3rd project is a technology verification satellite)",
      },
      {
        title: "First localization achievement and overwhelming performance",
        content:
          "Achieved the first localization of satellite-mounted devices that have been 100% dependent on imports and implemented overwhelming performance compared to foreign products.",
      },
      {
        title: "Application of non-volatile large-capacity memory",
        content:
          "For the first time in Korea, applied non-volatile NAND Flash components to the image data processing device of a practical satellite to dramatically increase the storage capacity and preserve data even if the power is interrupted.",
      },
      {
        title: "High-speed data processing",
        content:
          "High-speed data processing is possible through the parallel configuration of NAND Flash components, and high-speed data transmission is possible by implementing encryption and encoding algorithms that enable high-speed data transmission.",
      },
      {
        title: "Large capacity, small/lightweight, low power consumption",
        content:
          "Developed for mounting on medium-sized satellites, it has achieved large capacity of 1.5 Tb, small/lightweight of 15 kg, and low power consumption of 75 W. It boasts the world's highest level in terms of miniaturization, lightweight, and low power consumption compared to the same required functions.",
      },
    ],
    middleImg: {
      title: "Earth observation images of the CAS500-1",
      images: [
        {
          image: "/assets/imgs/renewal/business/Idhu/dokdo.webp",
          text: "Dokdo (2021. 3. 31)",
        },
        {
          image:
            "/assets/imgs/renewal/business/Idhu/jamsil-sports-complex.webp",
          text: "Seoul, Jamsil Sports Complex (2021. 04. 08)",
        },
      ],
    },
    desctiptImg: [
      {
        title: "Specifications of IDHU",
        src: "/assets/imgs/renewal/business/Idhu/item.webp",
      },
    ],
  },
};

//obc
export const obc = {
  KR: {
    header: "탑재컴퓨터",
    bgColor: MENU_BG_COLOR.SATELLITE_MOUNTED_DEVICE,
    bigImg: [
      {
        title: "탑재컴퓨터",
        content:
          "On-Board Computer<br/><br/>인공위성 시스템에서 위성 본체의 탑재컴퓨터는 지상국으로부터의 명령을 수신하여 인공위성 내의 모든 전자장치 전체를 제어하며 인공위성의모든 상태 정보를 수집하여 지상국에 내려 보내는 인공위성의 핵심 장치입니다. 한국항공우주산업(KAI)와 협력하여 개발하는 민간주도 신규 탑재컴퓨터로 차세대중형위성 3호(기술검증위성)에 탑재되어 국내 발사체인 누리호 4차로 발사될 예정입니다.",
        image: "/assets/imgs/renewal/business/Obc/main.webp",
      },
    ],
    feature: [
      {
        title: "최초의 민간 주도 개발 사업",
        content:
          "정부 주도의 우주 개발 사업이 아닌 민간 주도 첫 인공위성 시스템 개발 사업의 신형 탑재컴퓨터입니다. 차세대중형위성 3호 발사 이후 표준 탑재컴퓨터로 국내 위성 시리즈에 공급될 계획입니다.",
      },
      {
        title: "LEON3 Processor 탑재",
        content:
          "내부 보드간 통신을 위성용 차세대 통신 네트워크인 SpaceWire Network으로 구성하여 종래의 CAN 통신 대비 전력소모가 낮고 원활한 데이터 교환이 수행될 수 있도록 구현하였습니다.",
      },
      {
        title: "SpaceWire Network 구성",
        content:
          "내부 보드간 통신을 위성용 차세대 통신 네트워크인 SpaceWire Network으로 구성하여 종래의 CAN 통신 대비 전력소모가 낮고 원활한 데이터 교환이 수행될 수 있도록 구현하였습니다.",
      },
    ],
    desctiptImg: [
      {
        title: "OBC의 제원",
        src: "/assets/imgs/renewal/business/Obc/item.webp",
      },
    ],
  },
  EN: {
    header: "On-Board Computer",
    bgColor: MENU_BG_COLOR.SATELLITE_MOUNTED_DEVICE,
    bigImg: [
      {
        title: "On-Board Computer",
        content:
          "The on-board computer of the satellite system controls all electronic devices inside the artificial satellite, receives commands from the ground station, collects all status information of the artificial satellite, and sends it to the ground station. It is a core device of the artificial satellite. It is a new on-board computer developed by Lumir in cooperation with Korea Aerospace Industries (KAI) for the CAS500-3 (technology verification satellite) and is scheduled to be launched with Korea's launch vehicle Nuriho 4th.",
        image: "/assets/imgs/renewal/business/Obc/main.webp",
      },
    ],
    feature: [
      {
        title: "First privately led development project",
        content:
          "It is a new on-board computer developed by Lumir in cooperation with Korea Aerospace Industries (KAI) for the CAS500-3 (technology verification satellite) and is scheduled to be launched with Korea's launch vehicle Nuriho 4th.",
      },
      {
        title: "Equipped with LEON3 Processor",
        content:
          "The internal board-to-board communication is configured with the SpaceWire Network, the next-generation communication network for satellites, to reduce power consumption compared to the conventional CAN communication and perform smooth data exchange.",
      },
      {
        title: "Configuration of SpaceWire Network",
        content:
          "The internal board-to-board communication is configured with the SpaceWire Network, the next-generation communication network for satellites, to reduce power consumption compared to the conventional CAN communication and perform smooth data exchange.",
      },
    ],
    desctiptImg: [
      {
        title: "Specifications of OBC",
        src: "/assets/imgs/renewal/business/Obc/item.webp",
      },
    ],
  },
};

//cmgc

export const cmgc = {
  KR: {
    header: "제어모멘트자이로 제어장치",
    bgColor: MENU_BG_COLOR.SATELLITE_MOUNTED_DEVICE,
    bigImg: [
      {
        title:
          "달 탐사용 고속 고기동 위성의 제어모멘트자이로 제어장치 통신장치",
        content:
          "Control Moment Gyro Controller<br/><br/>제어모멘트자이로는 위성 본체 자세제어계 (AOCS: Attitude Orbit Control System)에 포함된 구동장치로, 기존에 쓰이던 반작용 휠 (reaction wheel)을 대체할 차세대 위성 구동장치입니다. 제어모멘트자이로는 높은 토크 성능과 모멘텀의 보존성으로 중형위성 이상의 위성에 주로 사용됩니다. 루미르는 국내 위성사업에서 전량 수입에 의존하던 제어모멘트자이로 제어장치의 국산화를 통하여, 기존 제품 대비 경량화/소형화 등 성능과 가격에서 경쟁력 우위를 확보하였습니다.",
        image: "/assets/imgs/renewal/business/Cmgc/main.webp",
      },
    ],
    wideImg: {
      title: "제어모멘트자이로 제어장치 개념도",
      src: "/assets/imgs/renewal/business/Cmgc/control-map.webp",
    },
    desctiptImg: [
      {
        title: "CMGC의 제원",
        src: "/assets/imgs/renewal/business/Cmgc/item.webp",
      },
    ],
  },
  EN: {
    header: "Control Moment Gyro Controller",
    bgColor: MENU_BG_COLOR.SATELLITE_MOUNTED_DEVICE,
    bigImg: [
      {
        title:
          "Control Moment Gyro Controller for High-Speed High-Mobility Satellite for Lunar Exploration",
        content:
          "Control Moment Gyro is a drive device included in the satellite body attitude control system (AOCS) that replaces the conventional reaction wheel. The control moment gyro is mainly used for satellites larger than medium-sized satellites due to its high torque performance and momentum conservation. Lumir has secured a competitive advantage in performance and price through the localization of the control moment gyro controller, which was entirely dependent on imports in the domestic satellite business.",
        image: "/assets/imgs/renewal/business/Cmgc/main.webp",
      },
    ],
    wideImg: {
      title: "Control Moment Gyro Controller Concept Map",
      src: "/assets/imgs/renewal/business/Cmgc/control-map.webp",
    },
    desctiptImg: [
      {
        title: "Specifications of CMGC",
        src: "/assets/imgs/renewal/business/Cmgc/item.webp",
      },
    ],
  },
};

//gavs
export const gavs = {
  KR: {
    pdf: {
      src: "/assets/pdfs/GAVS.pdf",
      downloadFileName: "[LUMIR] Solenoids for fuel injection GAVS Brochure",
    },
    header: "IECEx 인증<br/>대용량 솔레노이드",
    bgColor: MENU_BG_COLOR.CIVILIAN_DEVICE,
    bigImg: [
      {
        title: "선박 엔진 연료제어 밸브용 대용량 솔레노이드",
        content:
          "루미르는 인공위성 자세제어 자기 베어링 반작용 휠 기술을 활용한 대용량 솔레노이드를 개발하였습니다. 선박에서 사용되는 솔레노이드 방식의 연료제어 밸브는 주로 엔진의 연료 공급을 제어하고 관리하는 데 사용됩니다. 이러한 밸브들은 선박의 안전성과 효율성을 보장하고, 선박의 엔진 성능을 유지할뿐만 아니라 연료 소비를 최적화하며, 환경 규제를 준수하는데 필수적인 역할을 합니다.<br/><br/>IMO(국제해사기구)의 Nox 규제구역이 확대되고 세계 선급 협회에서도 선박용 엔진 능동 연료제어 밸브에 대한 IECEx 인증이 의무화되면서, 친환경 선박 기자재의 수요가 증가함에 따라 루미르가 개발한 솔레노이드의 수요는 더욱 많아질 것으로 예상됩니다. ",
        image: "/assets/imgs/renewal/business/Gavs/main.webp",
      },
    ],
    certification: [
      {
        title: "세계 최초 IECEx 방폭인증",
        content:
          "루미르가 개발한 대용량 솔레노이드는 Zero0, EPL Ga 등급의 세계 최초 IECEx 내압방폭 인증 제품입니다.",
        image: "/assets/imgs/renewal/business/Gavs/certification1-1.webp",
      },
      {
        title: "세계 유일 특허",
        content:
          "루미르의 솔레노이드는 국내 특허는 물론 미국 특허까지 확보하였습니다.",
        image: "/assets/imgs/renewal/business/Gavs/certification2-1.webp",
      },
    ],
    client: [
      {
        title: "한화에어로스페이스",
        image: "/assets/imgs/renewal/business/Gavs/한화에어로스페이스.webp",
      },
      {
        title: "HD 현대중공업",
        image: "/assets/imgs/renewal/business/Gavs/현대중공업.webp",
      },
    ],
    deviceList: [
      {
        deviceImg: "/assets/imgs/renewal/business/Gavs/device1.webp",
        itemImg: "/assets/imgs/renewal/business/Gavs/item1.webp",
      },
      {
        deviceImg: "/assets/imgs/renewal/business/Gavs/device2.webp",
        itemImg: "/assets/imgs/renewal/business/Gavs/item2.webp",
      },
      {
        deviceImg: "/assets/imgs/renewal/business/Gavs/device3.webp",
        itemImg: "/assets/imgs/renewal/business/Gavs/item3.webp",
      },
      {
        deviceImg: "/assets/imgs/renewal/business/Gavs/device4.webp",
        itemImg: "/assets/imgs/renewal/business/Gavs/item4.webp",
      },
    ],
  },
  EN: {
    pdf: {
      src: "/assets/pdfs/GAVS.pdf",
      downloadFileName: "[LUMIR] Solenoids for fuel injection GAVS Brochure",
    },
    header: "IECEx Certified<br/>Large Solenoid",
    bgColor: MENU_BG_COLOR.CIVILIAN_DEVICE,
    bigImg: [
      {
        title: "Large Solenoid for Ship Engine Fuel Control Valve",
        content:
          "Lumir has developed a large solenoid using the artificial satellite attitude control magnetic bearing reaction wheel technology. Solenoid-type fuel control valves used in ships are mainly used to control and manage the fuel supply of engines. These valves ensure the safety and efficiency of ships, maintain engine performance, optimize fuel consumption, and play an essential role in complying with environmental regulations.<br/><br/>As the IMO (International Maritime Organization) expands the Nox Emission Control Areas and the world's ship classification societies make IECEx certification mandatory for active fuel control valves for ship engines, the demand for Lumir's developed solenoids is expected to increase.",
        image: "/assets/imgs/renewal/business/Gavs/main.webp",
      },
    ],
    certification: [
      {
        title: "World's First IECEx Explosion-proof Certification",
        content:
          "The large solenoid developed by Lumir is the world's first IECEx explosion-proof certified product in Zero0, EPL Ga grades.",
        image: "/assets/imgs/renewal/business/Gavs/certification1-1.webp",
      },
      {
        title: "World's Only Patent",
        content:
          "Lumir's solenoid has secured not only domestic patents but also US patents.",
        image: "/assets/imgs/renewal/business/Gavs/certification2-1.webp",
      },
    ],
    client: [
      {
        title: "Hanwha Aerospace",
        image: "/assets/imgs/renewal/business/Gavs/한화에어로스페이스.webp",
      },
      {
        title: "HD Hyundai Heavy Industries",
        image: "/assets/imgs/renewal/business/Gavs/현대중공업.webp",
      },
    ],
    deviceList: [
      {
        deviceImg: "/assets/imgs/renewal/business/Gavs/device1.webp",
        itemImg: "/assets/imgs/renewal/business/Gavs/item1.webp",
      },
      {
        deviceImg: "/assets/imgs/renewal/business/Gavs/device2.webp",
        itemImg: "/assets/imgs/renewal/business/Gavs/item2.webp",
      },
      {
        deviceImg: "/assets/imgs/renewal/business/Gavs/device3.webp",
        itemImg: "/assets/imgs/renewal/business/Gavs/item3.webp",
      },
      {
        deviceImg: "/assets/imgs/renewal/business/Gavs/device4.webp",
        itemImg: "/assets/imgs/renewal/business/Gavs/item4.webp",
      },
    ],
  },
};
