import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { route } from "../../../router/route";
import { langState } from "../../../store/langState";
import PretendardText from "../../atoms/text/pretendardText";

import useDisplay from "../../../hooks/useDisplay";
import { languageType, menuLang } from "../../../lang/lang";
import InqueryModal from "../../../pages/modal/inqueryModal";
import { menuState } from "../../../store/menuState";
import HeaderSubMenu from "../../molecules/headerSubMenu";
import MHeader from "./mHeader";

import usePrefetch from "../../../hooks/usePrefetch";

const Header = () => {
  const { isTablet, isDesktop } = useDisplay();
  const { pathname } = useLocation();
  const navigation = useNavigate();
  const menuAtom = useRecoilValue(menuState);

  const [language, setLanguage] = useRecoilState(langState);

  const [isHome, setIsHome] = useState(true);
  const [langOpen, setLangOpen] = useState(false);
  const [mouseOver, setMouseOver] = useState("");
  const [isInqueryModal, setIsInqueryModal] = useState(false);

  const [mHeaderOpen, setMHeaderOpen] = useState(false);
  const [isAtTop, setIsAtTop] = useState(true);
  const [isVisible, setIsVisible] = useState(true);

  const prefetch = usePrefetch();

  let lastScrollTop = 0;

  useEffect(() => {
    if (mHeaderOpen) {
      document.body.style.overflow = "hidden";
      navigation(pathname, { replace: true });
    } else {
      document.body.style.overflow = "auto";
    }
  }, [mHeaderOpen, navigation, pathname]);

  useEffect(() => {
    const handlePopState = () => {
      if (mHeaderOpen) {
        setMHeaderOpen(false);
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [mHeaderOpen]);

  const handleMHeaderToggle = () => {
    if (!mHeaderOpen) {
      setMHeaderOpen(true);
      // mHeader를 열 때 현재 상태를 히스토리에 추가
      window.history.pushState({ mHeaderOpen: true }, "");
    } else {
      setMHeaderOpen(false);
    }
  };

  useEffect(() => {
    if (pathname === route.default || pathname === route.home) {
      setIsHome(true);
    } else {
      setIsHome(false);
    }
  }, [pathname]);

  useEffect(() => {
    function handleScroll() {
      const scrollTop = window.scrollY;
      setIsAtTop(scrollTop === 0);

      if (scrollTop > lastScrollTop && !mHeaderOpen) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }

      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Mobile Header Background Animation
  const [bgVisible, setBgVisible] = useState(false);

  useEffect(() => {
    let bgTimer;
    if (mHeaderOpen) {
      bgTimer = setTimeout(() => setBgVisible(true), 300); // Delay BG appearance
    } else {
      setBgVisible(false);
    }
    return () => {
      clearTimeout(bgTimer);
    };
  }, [mHeaderOpen]);

  return (
    <HeaderSection
      style={{
        background: isAtTop ? "#ffffff00" : "#fff",
        transform: isVisible ? "translateY(0)" : "translateY(-100%)",
        transition: "transform 0.3s ease-in-out",
      }}
    >
      <HeaderWrapper $isDesktop={isDesktop} $isTablet={isTablet}>
        <div>
          <img
            src={
              isHome && isAtTop
                ? "/assets/icons/logo_white.png"
                : "/assets/icons/logo_black.png"
            }
            alt=""
            // style={{ cursor: "pointer" }}
            className="custom-cursor"
            onClick={() => {
              sessionStorage.setItem("scrollPosition", 0);
              navigation(route.default);
            }}
          />
        </div>
        {isDesktop ? (
          <>
            <MenuWrapper $language={language}>
              {menuAtom?.map((item, index) => {
                return (
                  <MenuItem
                    key={index}
                    $isHome={isHome}
                    $isAtTop={isAtTop}
                    $hoverColor="#00aeef" // 호버 시 적용할 색상
                    onMouseEnter={() => {
                      setMouseOver(item.title);
                      prefetch(item.link); // 프리페치 추가
                      if (item.link === route.electronicAnnounce) {
                        prefetch(route.dart);
                      }
                    }}
                    onMouseLeave={() => setMouseOver("")}
                  >
                    <MenuText
                      key={index}
                      className="custom-cursor"
                      src={item.src}
                      style={{
                        fontWeight: pathname?.includes(item.src) ? 700 : 400,
                        color: isHome && isAtTop ? "#fff" : "",
                      }}
                      onClick={() => {
                        sessionStorage.setItem("scrollPosition", 0);
                        navigation(item.link);
                      }}
                    >
                      {item.title.startsWith("MENU")
                        ? menuLang[item.title][language]
                        : item.title}
                    </MenuText>
                    {item.subMenus2 && mouseOver === item.title ? (
                      <HeaderSubMenu list={item.subMenus2} />
                    ) : null}
                  </MenuItem>
                );
              })}
            </MenuWrapper>

            <BtnWrapper>
              <ContactBtn
                $isHome={isHome && isAtTop}
                onClick={() => {
                  setIsInqueryModal(true);
                }}
              >
                <MenuText
                  className="custom-cursor"
                  style={{
                    fontSize: "15px",
                    fontWeight: 600,
                    lineHeight: "20.7px",
                    color: `${isHome && isAtTop ? "#fff" : "#00AEEF"}`,
                    letterSpacing: "-1%",
                  }}
                >
                  {menuLang.CONTACT[language]}
                </MenuText>
              </ContactBtn>
              <div style={{ position: "relative" }}>
                <ChangeLangBtn onClick={() => setLangOpen(!langOpen)}>
                  <MenuText
                    className="custom-cursor"
                    style={{
                      width: "20px",
                      fontSize: "15px",
                      fontWeight: 400,
                      lineHeight: "20.7px",
                      color: `${isHome && isAtTop ? "#fff" : "#262C31"}`,
                      letterSpacing: "-1%",
                    }}
                  >
                    {language}
                  </MenuText>
                  <img src="/assets/icons/lang-toggle.png" alt="" />
                </ChangeLangBtn>
                {langOpen ? (
                  <ChangeLangSubMenu>
                    <LangText
                      onClick={() => {
                        setLanguage("KR");
                        setLangOpen(false);
                      }}
                    >
                      {languageType[0]}
                    </LangText>
                    <div
                      style={{
                        borderTop: "1px solid #f1f1f1",
                        margin: "15px 0",
                      }}
                    />
                    <LangText
                      onClick={() => {
                        setLanguage("EN");
                        setLangOpen(false);
                      }}
                    >
                      {languageType[1]}
                    </LangText>
                  </ChangeLangSubMenu>
                ) : null}
              </div>
            </BtnWrapper>
          </>
        ) : (
          <img
            src={
              isHome && isAtTop
                ? "/assets/icons/menu_white.png"
                : "/assets/icons/menu_gray.png"
            }
            alt=""
            // style={{ cursor: "pointer" }}
            onClick={handleMHeaderToggle}
          />
        )}
      </HeaderWrapper>

      {isInqueryModal ? <InqueryModal setVisiable={setIsInqueryModal} /> : null}
      <AnimatedMHeader $isOpen={mHeaderOpen}>
        <MHeader isOpen={mHeaderOpen} setMHeaderOpen={setMHeaderOpen} />
      </AnimatedMHeader>
      <MHeaderBg $visible={bgVisible} onClick={() => setMHeaderOpen(false)} />
    </HeaderSection>
  );
};

export default Header;

const HeaderSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 100;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  height: ${(props) => (props.$isDesktop ? "80px" : "65px")};
  padding: ${(props) =>
    props.$isDesktop ? "0" : props.$isTablet ? "0 70px" : "0 20px"};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MenuWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  /* justify-content: ${(props) =>
    props.$language === "EN" ? "center" : "flex-end"}; */
  justify-content: flex-end;
  /* margin-right: ${(props) => (props.$language === "EN" ? 0 : "39px")}; */
  margin-right: 39px;

  gap: 25px;
`;

const MenuItem = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${(props) => (props.$isHome && props.$isAtTop ? "#fff" : "#000")};
  transition: color 0.3s ease;

  &:hover {
    color: ${(props) => props.$hoverColor || "#00aeef"};
  }
`;

const MenuText = styled(PretendardText)`
  font-weight: 400;
  font-size: 16px;
  line-height: 23.2px;
  color: #5a636a;
  /* cursor: pointer; */
  &:hover {
    color: #00aeef;
  }
`;

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
`;

const ContactBtn = styled.button`
  min-width: 84px;
  height: 37px;
  padding: 8px 15px;
  border-radius: 4px;
  border: 1px solid;
  border-color: ${(props) => (props.$isHome ? "#fff" : "#00aeef")};
  cursor: none;
`;

const ChangeLangBtn = styled.button`
  height: 37px;
  padding: 8px 15px;
  border-radius: 4px;
  border: 1px solid #d7dce2;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  cursor: none;
`;

const ChangeLangSubMenu = styled.div`
  position: absolute;
  top: 59px;
  width: 90px;
  padding: 15px;
  background-color: #ffffff;
  box-shadow: 8px 8px 15px 0px rgba(0, 0, 0, 0.2);
`;

const LangText = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: #5a636a;
  /* cursor: pointer; */
`;

const MHeaderBg = styled.div`
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${(props) => (props.$visible ? 9 : -1)};
  opacity: ${(props) => (props.$visible ? 1 : 0)};
  transition: opacity 0.8s;
  visibility: ${(props) => (props.$visible ? " " : "hidden")};
`;

const AnimatedMHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transform: ${(props) =>
    props.$isOpen ? "translateX(0)" : "translateX(-100%)"};
  opacity: ${(props) => (props.$isOpen ? 1 : 0)};
  z-index: 1000;
`;
