import React, { useState, useEffect } from "react";
import styled from "styled-components";
import useDisplay from "../../../../hooks/useDisplay";

// interface PopupContent {
//   image: string;
//   alt: string;
// }

// interface TransparentPopupProps {
//   contents: PopupContent[];
//   onClose: () => void;
//   onDontShowToday: () => void;
// }

const TransparentPopup = ({ contents, onClose, onDontShowToday }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);

  const { isMobile } = useDisplay();

  useEffect(() => {
    const hasSeenToday = localStorage.getItem("popupSeenToday");
    if (hasSeenToday) {
      setIsOpen(false);
    }
  }, []);

  const handlePrevSlide = () => {
    setCurrentSlide((prev) => (prev > 0 ? prev - 1 : contents.length - 1));
  };

  const handleNextSlide = () => {
    setCurrentSlide((prev) => (prev < contents.length - 1 ? prev + 1 : 0));
  };

  const handleDontShowToday = () => {
    localStorage.setItem("popupSeenToday", "true");
    onDontShowToday();
    setIsOpen(false);
  };

  const handleClose = () => {
    onClose();
    setIsOpen(false);
  };

  if (!isOpen) return null;

  if (contents.length === 0) return null;

  return (
    <PopupOverlay $isMobile={isMobile}>
      <PopupContent $isMobile={isMobile}>
        <ImageSlider $isMobile={isMobile}>
          <SliderImage
            src={
              isMobile
                ? contents[currentSlide].image.mobile
                : contents[currentSlide].image.pc
            }
            alt={contents[currentSlide].alt}
          />
          <PrevButton onClick={handlePrevSlide} aria-label="이전 슬라이드">
            &#10094;
          </PrevButton>
          <NextButton onClick={handleNextSlide} aria-label="다음 슬라이드">
            &#10095;
          </NextButton>
        </ImageSlider>
        <NavigationDots>
          {contents.map((_, index) => (
            <Dot
              key={index}
              active={index === currentSlide}
              onClick={() => setCurrentSlide(index)}
              className="custom-cursor"
            />
          ))}
        </NavigationDots>
        <ButtonContainer>
          <PopupButton onClick={handleDontShowToday}>
            오늘 하루 보지 않기
          </PopupButton>
          <PopupButton onClick={handleClose}>닫기</PopupButton>
        </ButtonContainer>
      </PopupContent>
    </PopupOverlay>
  );
};

export default TransparentPopup;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  right: ${(props) => (props.$isMobile ? "0" : "5%")};
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: ${(props) => (props.$isMobile ? "center" : "flex-end")};
  align-items: center;
  z-index: 1000;
`;

const PopupContent = styled.div`
  background-color: rgba(114, 114, 114, 0.3);
  padding: 15px;
  border-radius: 10px;
  position: relative;
  max-width: 90%;
  width: ${(props) => (props.$isMobile ? "335px" : "450px")};
  //블러효과
  backdrop-filter: blur(10px);
`;

const ImageSlider = styled.div`
  position: relative;
  width: 100%;
  padding-top: ${(props) =>
    props.$isMobile ? "100%" : "56.25%"}; // 16:9 Aspect Ratio (9 / 16 = 0.5625)
  overflow: hidden;
`;

const SliderImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  opacity: 0.85;
`;

const SliderButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
`;

const PrevButton = styled(SliderButton)`
  left: 10px;
  cursor: none;
  border-radius: 10px;
  /* background-color: rgba(0, 0, 0, 0); */
`;

const NextButton = styled(SliderButton)`
  right: 10px;
  cursor: none;
  border-radius: 10px;
  /* background-color: rgba(0, 0, 0, 0); */
`;

const NavigationDots = styled.div`
  text-align: center;
  margin-top: 10px;
`;

const Dot = styled.span`
  height: 10px;
  width: 10px;
  background-color: ${(props) => (props.active ? "white" : "#bbb")};
  border-radius: 50%;
  display: inline-block;
  margin: 0 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const PopupButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: none;
  padding: 5px 10px;
`;
