export const route = {
  default: "/",

  // 홈
  home: "/home",

  //회사소개
  introduce: "/introduce",
  overview: "/introduce/overview",
  history: "/introduce/history",

  major_performance: "/introduce/major-performance",
  heritage: "/introduce/major-performance/heritage",
  nationalRnD: "/introduce/major-performance/national-RnD",
  certification: "/introduce/major-performance/certification",

  workspace: "/introduce/workspace",
  direction: "/introduce/direction",

  //사업소개
  business: "/products",
  constellation: "/business/constellation",

  old_space: "/business/oldspace",
  satellite_mounted: "/business/oldspace/satellite-mounted",
  nextGenerationHeavySatellite:
    "/business/oldspace/satellite-mounted/heavy-satellite5",

  lunarExploration: "/business/oldspace/satellite-mounted/lunar-exploration",
  imageDataProcessing:
    "/business/oldspace/satellite-mounted/image-data-processing",
  explorationComputer:
    "/business/oldspace/satellite-mounted/exploration-computer",
  controlDevice: "/business/oldspace/satellite-mounted/control-device",

  // electric_ground: "/business/oldspace/electric-ground",
  xBand: "/business/oldspace/electric-ground/x-band",
  bodyPowerMeter: "/business/oldspace/electric-ground/body-power-meter",

  new_space: "/business/newspace",
  lumirX: "/business/newspace/lumir-x",
  catis: "/business/newspace/lumir-x/CATIS",
  sardip: "/business/newspace/lumir-x/SARDIP",

  lumirV: "/business/newspace/lumir-v",

  new_business: "/business/newspace/newbusiness",
  dataFusion: "/business/newspace/newbusiness/data-fusion",

  serviceScope: "/business/service-scope",
  gavs: "/business/GAVS",

  //사업소개 - renewal
  lumirX_renewal: "/products#satellite_system?device=LumirX",
  cas500_5_renewal: "/products#satellite_system?device=CAS500-5",
  catis_renewal: "/products/catis",
  KaPA_renewal: "/products/kapa",
  DTNPL_renewal: "/products/dtnpl",
  IDHU_renewal: "/products/idhu",
  OBC_renewal: "/products/obc",
  CMGC_renewal: "/products/cmgc",
  XDM_EGSE_renewal: "/products/xdm-egse",
  K7P_EGSE_renewal: "/products/k7p-egse",
  GAVS_renewal: "/products/gavs",

  satellite_system: "satellite_system",
  satellite_mounted_device: "satellite_mounted_device",
  electric_ground: "electric_ground",
  civilian_device: "civilian_device",

  satellite_mounted_device_DTNPL:
    "/products#satellite_mounted_device?device=DTNPL",
  satellite_mounted_device_OBC: "/products#satellite_mounted_device?device=OBC",
  satellite_mounted_device_IDHU:
    "/products#satellite_mounted_device?device=IDHU",

  //data
  data: "/data",
  service: "/service",
  sarImage: "sar_image_processing",
  ai: "ai_based_object_detection",
  disaster: "disaster_damage_analysis",
  monitoring: "monitoring_urban_change",
  water_facility: "manage_water_facility_safety",

  //투자
  investment: "/investment",

  financial_info: "/investment/financial-info",
  managementInfo: "/investment/financial-info/management-info",
  managementInfoDetail: "/investment/financial-info/management-info/detail",
  businessReport: "/investment/financial-info/bsn-report",
  reviewReport: "/investment/financial-info/review-report",
  governanceReport: "/investment/financial-info/governance-report",
  electronicAnnounce: "/investment/financial-info/electronic-announcement",
  dart: "https://dart.fss.or.kr/html/search/SearchCompany_M2.html?textCrpNM=%EB%A3%A8%EB%AF%B8%EB%A5%B4",

  performance: "/investment/performance",
  performanceAnnounce: "/investment/performance/performance-announce",
  IREvent: "/investment/performance/IR-event",
  eventMaterials: "/investment/performance/event-materials",

  shareholder: "/investment/shareholder",
  communication: "/investment/shareholder/communication",
  meeting: "/investment/shareholder/meeting",

  stockInfo: "/investment/stock-information",
  notice: "/investment/notice",
  noticeDetail: "/investment/notice/detail",

  //cyber_relation
  cyber_relation: "/cyber-relation",
  newArticle: "/cyber-relation/new-article",
  lumirNews: "/cyber-relation/lumir-news",
  lumirNewsDetail: "/cyber-relation/lumir-news-detail",

  public_relations_office: "/cyber-relation/public-relations-office",
  brochure: "/cyber-relation/public-relations-office/brochure",
  videoGallery: "/cyber-relation/public-relations-office/video-gallery",

  //recruit
  recruit: "/recruit",

  talentType: "/recruit/talent-type",
  jobAnnounce: "/recruit/job-announcement",
  // jobAnnounce:
  //   "https://www.saramin.co.kr/zf_user/company-info/view-inner-recruit?csn=clE1WEZscDVaeGZJb2JLU2NOTlRpQT09",
  jobAnnounceDetail: "/recruit/job-announcement/detail",
  // jobAnnounceDetail: "https://app.lumir.space/lrim/apply",
  jobAnnounceSaramin:
    "https://www.saramin.co.kr/zf_user/company-info/view-inner-recruit?csn=clE1WEZscDVaeGZJb2JLU2NOTlRpQT09",
  faq: "/recruit/faq",
};
